




























































































































































































































































































































































































































































































import Vue from 'vue'

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BLink,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BBadge,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { AxiosResponse } from 'axios'
import store from '@/store'
import GroupCreateModal from '@/views/admin/group/group-forms/GroupCreateModal.vue'
import GroupTypeCreateModal from '@/views/admin/group-types/goup-types-add/GroupTypeCreateModal.vue'
import GroupBulkEditModal from '@/views/admin/group/group-forms/GroupBulkEditModal.vue'
import GroupBulkAssignModal from '@/views/admin/group/group-forms/GroupBulkAssignModal.vue'
import GroupBulkCreateModal from '@/views/admin/group/group-forms/GroupBulkCreateModal.vue'
import WarningModal from '@/views/components/warning-modal/WarningModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import ApplicationsForGroupAutoAssignModal
  from '@/views/components/applications-for-group-auto-asign-modal/ApplicationsForGroupAutoAssignModal.vue'
import GenerateGroupListTemplateModal from '@/views/components/generate-group-list-template-modal/GenerateGroupListTemplateModal.vue'
import GroupUsers from '@/views/admin/group/group-list/GroupUsers.vue'
import GenerateBadgesModal from '@/views/components/generate-badges-modal/GenerateBadgesModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import NewTaskModal from '@/views/components/new-task-modal/NewTaskModal.vue'
import { appGroupsList } from '@/store/modules/app-groups/app-groups-list'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { AllGroupsList, IGroupItemState } from '@/store/modules/app-groups/app-groups-list/types'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'AllGroupsList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BButton,
    BFormCheckbox,
    BOverlay,

    vSelect,
    GroupCreateModal,
    GroupTypeCreateModal,
    GroupBulkEditModal,
    GroupBulkAssignModal,
    GroupBulkCreateModal,
    WarningModal,
    ConfirmationModal,
    ApplicationsForGroupAutoAssignModal,
    GenerateGroupListTemplateModal,
    GroupUsers,
    GenerateBadgesModal,
    NewTaskModal,
  },
  data(): AllGroupsList {
    return {
      permissionSubjects,
      currentRouteName: this.$router.currentRoute.name,
      deletableGroup: {
        id: null,
        name: null,
      },
      markedGroups: [],
      groupsPerPageCheckbox: false,
      createdGroupId: null,
      applicationsForAutoAssign: [],
      selectedGroup: null,
      currentGroupsUsers: [],
    }
  },
  computed: {
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    filterStatusOptions() {
      return appGroupsList.getters.getFilterStatusOptions
    },
    filterTuitionFeeOptions() {
      return appGroupsList.getters.getFilterTuitionFeeOptions
    },
    filterSessionOptions() {
      return appGroupsList.getters.getFilterSessionOptions
    },
    groupTypeOptions() {
      return appGroupsList.getters.getGroupTypeOptions
    },
    dataMeta() {
      return appGroupsList.getters.getDataMeta
    },
    perPage() {
      return appGroupsList.getters.getPerPage
    },
    totalItems() {
      return appGroupsList.getters.getTotalItems
    },
    perPageOptions() {
      return appGroupsList.getters.getPerPageOptions
    },
    tableColumns() {
      return appGroupsList.getters.getTableColumns
    },
    currentPage: {
      get() {
        return appGroupsList.getters.getCurrentPage
      },
      set(val: number) {
        appGroupsList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    searchQuery: {
      get() {
        return appGroupsList.getters.getSearchQuery
      },
      set(val: string) {
        appGroupsList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy: {
      get() {
        return appGroupsList.getters.getSortBy
      },
      set(val: string) {
        appGroupsList.mutations.SET_SORT_BY(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appGroupsList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appGroupsList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    filterDataSession: {
      get() {
        return appGroupsList.getters.getFilterDataSession
      },
      set(val: any) {
        appGroupsList.mutations.SET_FILTER_DATA_SESSION(val)
      },
    },
    filterDataStatus: {
      get() {
        return appGroupsList.getters.getFilterDataStatus
      },
      set(val: any) {
        appGroupsList.mutations.SET_FILTER_DATA_STATUS(val)
      },
    },
    filterDataType: {
      get() {
        return appGroupsList.getters.getFilterDataType
      },
      set(val: any) {
        appGroupsList.mutations.SET_FILTER_DATA_TYPE(val)
      },
    },
    filterDataTuition: {
      get() {
        return appGroupsList.getters.getFilterDataTuition
      },
      set(val: any) {
        appGroupsList.mutations.SET_FILTER_DATA_TUITION(val)
      },
    },
    isLoading: {
      get() {
        return appGroupsList.getters.getIsLoading
      },
      set(val: boolean) {
        appGroupsList.mutations.SET_IS_LOADING(val)
      },
    },
    expandAll: {
      get() {
        return appGroupsList.getters.getExpandAll
      },
      set(val: boolean) {
        appGroupsList.mutations.SET_EXPAND_ALL(val)
      },
    },
  },
  watch: {
    dataMeta() {
      this.handleGroupsPerPageCheckbox()
    },
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    filterDataSession(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataSession', val)
      }
    },
    filterDataStatus(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataStatus', val)
      }
    },
    filterDataType(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataType', val)
      }
    },
    filterDataTuition(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataTuition', val)
      }
    },
  },
  async created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }

    await appGroupsList.actions.fetchSessions()
    await appGroupsList.actions.fetchPpaList()
    await appGroupsList.actions.fetchGroupTypesList()
    await appGroupsList.actions.fetchTagSetsListOptions()
  },
  methods: {
    async fetchGroupsList() {
      return appGroupsList.actions.fetchGroupsList()
    },
    clearFilters() {
      appGroupsList.mutations.CLEAR_FILTERS()
    },
    setFilterParams() {
      appGroupsList.mutations.SET_FILTER_PARAMS()
    },
    updatePerPage(val: number) {
      appGroupsList.mutations.SET_PER_PAGE(val)
    },
    handeDeleteBtn(group: any) {
      this.deletableGroup.id = group.id
      this.deletableGroup.name = group.name

      if (group.member_count > 0) {
        this.$bvModal.show('warning-delete-group')
      } else {
        this.$bvModal.show('confirmation-delete-group')
      }
    },
    async deleteGroup() {
      if (this.deletableGroup.id != null) {
        await appGroupsList.actions.deleteGroup(this.deletableGroup.id)
        this.closeDeleteConfirmationModal()
        this.updateTable()
      }
    },
    closeDeleteConfirmationModal() {
      this.deletableGroup.id = null
      this.deletableGroup.name = null
      this.$bvModal.hide('confirmation-delete-group')
      this.$bvModal.hide('warning-delete-group')
    },
    updateTable() {
      (this.$refs.refGroupsListTable as BTable).refresh()
    },
    resolveStatusVariant(status: any) {
      if (status.toLocaleString() === 'Active') return 'info'
      if (status.toLocaleString() === 'Inactive') return 'danger'
      return 'primary'
    },
    markGroupsOnPage(val: boolean) {
      // eslint-disable-next-line prefer-destructuring
      const localItems = (this.$refs.refGroupsListTable as BTable).localItems

      if (val) {
        localItems.forEach((group: IGroupItemState) => {
          const index = this.markedGroups.indexOf(group.id)
          if (index < 0) {
            this.markedGroups.push(group.id)
          }
        })
      } else {
        localItems.forEach((group: IGroupItemState) => {
          const index = this.markedGroups.indexOf(group.id)
          if (index >= 0) {
            this.markedGroups.splice(index, 1)
          }
        })
      }
    },
    handleGroupsPerPageCheckbox() {
      let counter = 0
      // eslint-disable-next-line prefer-destructuring
      const localItems = (this.$refs.refGroupsListTable as BTable).localItems

      localItems.forEach((group: IGroupItemState) => {
        if (this.markedGroups.indexOf(group.id) !== -1) {
          counter += 1
        }
      })

      this.groupsPerPageCheckbox = !!(localItems && localItems.length > 0 && counter === localItems.length)
    },
    finishBulkEdit() {
      this.markedGroups = []
      this.groupsPerPageCheckbox = false
      this.updateTable()
    },
    async updateGroupsData() {
      await appGroupsList.actions.fetchGroupTypesList()
      this.updateTable()
    },
    async exportGroupsCSV() {
      await appGroupsList.actions.exportGroupsCSV()
    },
    async findApplicationsForAutoAssign(groupId: string) {
      if (!groupId) {
        return
      }

      this.createdGroupId = groupId
      this.applicationsForAutoAssign = []
      await appGroupsList.actions.fetchApplicationsForGroupAutoAssign(groupId)
        .then((response: AxiosResponse) => {
          this.applicationsForAutoAssign = response.data.data
        })

      if (this.applicationsForAutoAssign.length > 0) {
        this.$bvModal.show('applications-for-group-auto-assign')
      }
    },
    openGenerateGroupListTemplateModal(group: IGroupItemState) {
      this.selectedGroup = group
      this.$nextTick(() => {
        this.$bvModal.show('generate-group-list-template')
      })
    },
    openGenerateBadgesModal(group: IGroupItemState) {
      this.selectedGroup = group
      this.$nextTick(() => {
        this.$bvModal.show('generate-badges')
      })
    },
    resetSelectedGroup() {
      this.selectedGroup = null
    },
    async downloadBadges(group: IGroupItemState) {
      await appGroupsList.actions.exportGroupsBadgesZip(group)
    },
    async toggleExpandAll() {
      try {
        this.expandAll = !this.expandAll
        this.isLoading = true

        const localStorageItemName = `groupPpaColumns-${this.programId}`
        // eslint-disable-next-line prefer-destructuring
        const localItems = (this.$refs.refGroupsListTable as BTable).localItems
        const item = localStorage.getItem(localStorageItemName)

        if (this.expandAll) {
          const queryParams = {
            group_ids: localItems.map((group: IGroupItemState) => group.id),
            ppa: item ? JSON.parse(item) : null,
          }
          this.currentGroupsUsers = await appGroupsList.actions.fetchGroupsUsersList(queryParams)
        }

        localItems.forEach((group: IGroupItemState) => {
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          group._showDetails = this.expandAll
        })
      } finally {
        this.isLoading = false
      }
    },
    getGroupUsersByGroupId(id: string) {
      return this.currentGroupsUsers[id] || null
    },
  },
})
