import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import {
  GroupBulkEditState,
  IGeneralSettingsState,
  IGroupAccessAndBehaviorSettingsState,
  IGroupItemState,
  IGroupsListRequest,
  IUpdateGroupsRequest,
} from './types'
import {
  fetchGroupAutoAssignRules,
  fetchGroupCustomFields,
  fetchGroups,
  saveBulkGroupChanges,
} from '@/api/groups'
import { fetchGroupType } from '@/api/groupTypes'

// eslint-disable-next-line import/prefer-default-export
export const appGroupBulkEdit = createModule(
  'appGroupBulkEdit',
  {
    namespaced: true,
    state: {
      isLoading: false,
      haveDifferentGroupTypes: false,
      selectedGroupsIds: [],
      items: [],
      sessionsList: [],
      groupTypeFieldsList: [],
      groupFieldsList: [],
      groupAssignRulesList: [],
      groupAccessAndBehaviorSettings: {
        auto_removing: false,
        capacity: 0,
        has_tuition_fee: false,
        is_allow_use_selection: false,
        is_allow_viewing_other_members: false,
        is_capacity_enforcement: false,
        is_limit_selection: false,
        is_restrict_single_group_type: false,
        is_visible: false,
        limit_selection_conditions: [],
        recalculate_balance: [],
        visible_members_fields: [],
      },
      generalSettings: {
        groupName: null,
        groupNameForAll: false,
        groupStatus: null,
        groupStatusForAll: false,
        sessions: [],
        sessionsForAll: false,
        sessionsIsCustomPricingAvailable: false,
        sessionsIsDetachOld: false,
        sessionsCustomPricingForAll: false,
        groupTypeCustomFieldsForAll: false,
        groupCustomFieldsForAll: false,
        autoAssignmentForAll: false,
        groupAccessAndBehaviorForAll: false,
      },
      groupStatusOptions: [
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
      ],
      staffListMetaData: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        has_prev_page: false,
        has_next_page: true,
      },
    } as GroupBulkEditState,
    getters: {
      getIsLoading: state => state.isLoading,
      getSelectedGroupsIds: state => state.selectedGroupsIds,
      getItems: state => state.items,
      getSessionsList: state => state.sessionsList,
      getHaveDifferentGroupTypes: state => state.haveDifferentGroupTypes,
      getGroupTypeFieldsList: state => state.groupTypeFieldsList,
      getGroupFieldsList: state => state.groupFieldsList,
      getGroupAssignRulesList: state => state.groupAssignRulesList,
      getGroupAccessAndBehaviorSettings: state => state.groupAccessAndBehaviorSettings,
      getGeneralSettings: state => state.generalSettings,
      getGroupStatusOptions: state => state.groupStatusOptions,
      getModifiedGroups: state => state.items.filter((group: IGroupItemState) => group.isModified),
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_SELECTED_GROUPS_IDS(state, val: any[]) {
        state.selectedGroupsIds = val
      },
      SET_ITEMS(state, val: []) {
        state.items = val
      },
      SET_SESSIONS_LIST(state, val: []) {
        state.sessionsList = val
      },
      SET_HAVE_DIFFERENT_GROUP_TYPES(state, val: boolean) {
        state.haveDifferentGroupTypes = val
      },
      SET_GROUP_TYPE_FIELDS_LIST(state, val: []) {
        state.groupTypeFieldsList = val
      },
      SET_GROUP_FIELDS_LIST(state, val: []) {
        state.groupFieldsList = val
      },
      SET_GROUP_ASSIGN_RULES_LIST(state, val: []) {
        state.groupAssignRulesList = val
      },
      SET_GROUP_ACCESS_AND_BEHAVIOR_SETTINGS(state, val: IGroupAccessAndBehaviorSettingsState) {
        state.groupAccessAndBehaviorSettings = val
      },
      SET_GENERAL_SETTINGS(state, val: IGeneralSettingsState) {
        state.generalSettings = val
      },
      UPDATE_GENERAL_SETTINGS_PROPERTY(state, { property, value }) {
        if (Object.prototype.hasOwnProperty.call(state.generalSettings, property)) {
          state.generalSettings[property] = value
        }
      },
      CLEAN_DATA(state) {
        state.generalSettings = {
          groupName: null,
          groupNameForAll: false,
          groupStatus: null,
          groupStatusForAll: false,
          sessions: [],
          sessionsForAll: false,
          sessionsIsCustomPricingAvailable: false,
          sessionsIsDetachOld: false,
          sessionsCustomPricingForAll: false,
          groupTypeCustomFieldsForAll: false,
          groupCustomFieldsForAll: false,
          autoAssignmentForAll: false,
          groupAccessAndBehaviorForAll: false,
        }
      },
    },
    actions: {
      async updateGeneralSettingsProperty({ commit }, { property, value }) {
        commit('UPDATE_GENERAL_SETTINGS_PROPERTY', { property, value })
      },
      async cleanData({ commit }) {
        commit('CLEAN_DATA')
      },
      async fetchGroupsList({ state, rootGetters }) {
        try {
          const queryParams: IGroupsListRequest = {
            exceptProgramType: 'Program',
            programId: rootGetters['verticalMenu/getDefaultProgram'],
            sortBy: 'name',
            sortDesc: true,
            groupId: state.selectedGroupsIds,
            detailed: true,
          }

          const response: any = await fetchGroups(queryParams)

          appGroupBulkEdit.mutations.SET_ITEMS(response.data.data)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching groups list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async saveGroupChanges({ state }) {
        try {
          appGroupBulkEdit.mutations.SET_IS_LOADING(true)

          const queryParams: IUpdateGroupsRequest = {
            groups: state.selectedGroupsIds,
            general_settings: state.generalSettings,
            group_type_fields_list: [],
            group_fields_list: [],
            group_assign_rules_list: [],
            group_access_and_behavior_settings: [],
          }

          if (state.generalSettings.groupTypeCustomFieldsForAll) {
            queryParams.group_type_fields_list = state.groupTypeFieldsList.map(groupTypeField => ({
              group_type_custom_field_id: groupTypeField.id,
              value: groupTypeField.value,
              attribute: groupTypeField.name,
              label: groupTypeField.label,
            }))
          }

          if (state.generalSettings.groupCustomFieldsForAll) {
            queryParams.group_fields_list = state.groupFieldsList.map(groupField => ({
              value: groupField.value,
              attribute: groupField.attribute,
              label: groupField.label,
            }))
          }

          if (state.generalSettings.autoAssignmentForAll) {
            queryParams.group_assign_rules_list = state.groupAssignRulesList
          }

          if (state.generalSettings.groupAccessAndBehaviorForAll) {
            queryParams.group_access_and_behavior_settings = state.groupAccessAndBehaviorSettings
          }

          const modifiedGroups = appGroupBulkEdit.getters.getModifiedGroups
          if (modifiedGroups.length) {
            queryParams.modified_groups = modifiedGroups
          }

          const response = await saveBulkGroupChanges(queryParams)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully updated groups',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appGroupBulkEdit.mutations.SET_IS_LOADING(false)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating groups',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error.response
        } finally {
          appGroupBulkEdit.mutations.SET_IS_LOADING(false)
        }
      },
      async prepareGroupTypeInfo({ state, dispatch }) {
        if (!state.items || state.items.length === 0) {
          appGroupBulkEdit.mutations.SET_HAVE_DIFFERENT_GROUP_TYPES(true)
          return null
        }

        const firstTypeId = state.items[0].type_id
        const haveSameGroupTypes = state.items.every(item => item.type_id === firstTypeId)
        appGroupBulkEdit.mutations.SET_HAVE_DIFFERENT_GROUP_TYPES(!haveSameGroupTypes)

        if (!haveSameGroupTypes) {
          return null
        }

        try {
          const response: any = await fetchGroupType(firstTypeId)

          const groupType = response.data.data
          const defaultCustomFields = groupType.fields
          const staffFields = defaultCustomFields.filter((field:any) => field.type === 'staff')

          if (staffFields.length) {
            await dispatch('appGroupCustomFieldRowFromType/fetchStaffList', null, { root: true })
          }

          appGroupBulkEdit.mutations.SET_GROUP_TYPE_FIELDS_LIST(defaultCustomFields)
          appGroupBulkEdit.mutations.SET_GROUP_ACCESS_AND_BEHAVIOR_SETTINGS(groupType.settings)

          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching group type',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async prepareGroupCustomFields({ state }) {
        try {
          const response: any = await fetchGroupCustomFields({
            groupId: state.selectedGroupsIds,
          })

          const groupCustomFieldsList = response.data.data.filter((form:any) => form.group_type_custom_field_id === null)
          appGroupBulkEdit.mutations.SET_GROUP_FIELDS_LIST(groupCustomFieldsList)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching groups custom fields',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async prepareGroupAutoAssignRules({ state }) {
        try {
          const response: any = await fetchGroupAutoAssignRules({
            groupId: state.selectedGroupsIds,
          })

          const validRules = response.data.data.filter((rule:any) => {
            const customFields = Array.isArray(rule.customField)
              ? rule.customField
              : [rule.customField]
            return customFields.every((field:any) => state.groupFieldsList.some((item:any) => item.id === field))
          })

          appGroupBulkEdit.mutations.SET_GROUP_ASSIGN_RULES_LIST(validRules)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching groups auto assign rules',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
    },
  },
)

appGroupBulkEdit.register(store)
