<template>
  <b-col
    cols-12
  >
    <group-custom-field-row
      v-for="item in fieldsList"
      :key="item.id"
      class="mb-2"
      :field-data="item"
      @removeFieldsRow="removeFieldsRow"
      @change="$emit('change')"
    />

    <b-button
      variant="outline-primary"
      class="d-flex align-items-center justify-content-center w-100 my-1"
      @click="addNewFieldsRow"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="mr-1"
      />
      Add Field
    </b-button>
  </b-col>
</template>

<script>
import {
  BCol,
  BButton,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'
import GroupCustomFieldRow from '@/views/admin/group/group-forms/components/GroupCustomFieldRow.vue'

export default {
  components: {
    // BRow,
    BCol,
    BButton,

    GroupCustomFieldRow,
  },
  props: {
    fieldsList: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      fieldsCount: 0,
    }
  },
  watch: {
    fieldsList: {
      deep: true,
      handler() {
        this.fieldsCount = this.fieldsList.length
      },
    },
  },
  methods: {
    isFieldKeyExists(key) {
      return this.fieldsList.some(field => field.attribute === `field_${key}`)
    },
    addNewFieldsRow() {
      do {
        this.fieldsCount += 1
      } while (this.isFieldKeyExists(this.fieldsCount))

      this.fieldsList.push({
        id: uuidv4(),
        label: `Field ${this.fieldsCount}`,
        attribute: `field_${this.fieldsCount}`,
        value: '',
        group_type_custom_field_id: null,
      })
    },
    removeFieldsRow(id) {
      const index = this.fieldsList.findIndex(item => item.id === id)
      this.fieldsList.splice(index, 1)
    },
  },
}
</script>
