import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import {
  GroupCustomFieldRowFromTypeState,
  IStaffListRequest,
} from './types'
import { fetchUsersList } from '@/api/users'

// eslint-disable-next-line import/prefer-default-export
export const appGroupCustomFieldRowFromType = createModule(
  'appGroupCustomFieldRowFromType',
  {
    namespaced: true,
    state: {
      isLoading: false,
      staffList: [],
      staffListMetaData: {
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
        hasPrevPage: false,
        hasNextPage: true,
        staffSearchQuery: null,
      },
    } as GroupCustomFieldRowFromTypeState,
    getters: {
      getIsLoading: state => state.isLoading,
      getStaffList: state => state.staffList,
      getStaffListMetaData: state => state.staffListMetaData,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_STAFF_LIST(state, val: []) {
        state.staffList = val
      },
      SET_STAFF_LIST_META_DATA_CURRENT_PAGE(state, val: number) {
        state.staffListMetaData.currentPage = val
      },
      SET_STAFF_LIST_META_DATA_LAST_PAGE(state, val: number) {
        state.staffListMetaData.lastPage = val
      },
      SET_STAFF_LIST_META_DATA_HAS_PREV_PAGE(state, val: boolean) {
        state.staffListMetaData.hasPrevPage = val
      },
      SET_STAFF_LIST_META_DATA_HAS_NEXT_PAGE(state, val: boolean) {
        state.staffListMetaData.hasNextPage = val
      },
      CLEAN_DATA(state) {
        state.staffListMetaData = {
          currentPage: 1,
          lastPage: 1,
          perPage: 10,
          hasPrevPage: false,
          hasNextPage: true,
          staffSearchQuery: null,
        }
        state.staffList = []
      },
      SET_STAFF_SEARCH(state, val: string) {
        state.staffListMetaData.staffSearchQuery = val
      },
    },
    actions: {
      async cleanData({ commit }) {
        commit('CLEAN_DATA')
      },
      async searchStaff({ commit, dispatch }, val: string) {
        commit('SET_STAFF_SEARCH', val)
        commit('SET_STAFF_LIST_META_DATA_CURRENT_PAGE', 1)
        await dispatch('fetchStaffList')
      },
      async makePagination({ commit, dispatch }, val: number) {
        commit('SET_STAFF_LIST_META_DATA_CURRENT_PAGE', val)
        await dispatch('fetchStaffList')
      },
      async fetchStaffList({ state, rootGetters }) {
        try {
          appGroupCustomFieldRowFromType.mutations.SET_IS_LOADING(true)
          const queryParams: IStaffListRequest = {
            programId: rootGetters['verticalMenu/getDefaultProgram'],
            perPage: state.staffListMetaData.perPage,
            page: state.staffListMetaData.currentPage,
            sortBy: 'name',
            sortDesc: false,
            role: 'staff',
            getForSelect: true,
          }
          if (state.staffListMetaData.staffSearchQuery) queryParams.name = state.staffListMetaData.staffSearchQuery
          const response:any = await fetchUsersList(queryParams)

          const {
            last_page: lastPage, current_page: currentPage,
          } = response.data.meta

          const users = response.data.data.map((item:any) => ({ id: item.id, label: item.full_name }))

          appGroupCustomFieldRowFromType.mutations.SET_STAFF_LIST(users)
          appGroupCustomFieldRowFromType.mutations.SET_STAFF_LIST_META_DATA_CURRENT_PAGE(currentPage)
          appGroupCustomFieldRowFromType.mutations.SET_STAFF_LIST_META_DATA_LAST_PAGE(lastPage)
          appGroupCustomFieldRowFromType.mutations.SET_STAFF_LIST_META_DATA_HAS_NEXT_PAGE(lastPage > currentPage)
          appGroupCustomFieldRowFromType.mutations.SET_STAFF_LIST_META_DATA_HAS_PREV_PAGE(currentPage > 1)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching staff' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        } finally {
          appGroupCustomFieldRowFromType.mutations.SET_IS_LOADING(false)
        }
      },
    },
  },
)

appGroupCustomFieldRowFromType.register(store)
