import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { fetchFileFoldersList } from '@/api/files'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchFileFoldersList(ctx, queryParams) {
      try {
        return await fetchFileFoldersList(queryParams)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching File Folders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
  },
}
