<template>

  <b-row>
    <b-col
      cols="12"
    >
      <!-- Loading -->
      <b-card
        v-if="isLoading"
      >
        Loading...
      </b-card>

      <!-- Table Container Card -->
      <b-card
        v-else
        no-body
      >
        <FormResultEditRow
          v-for="(item, index) in fieldsList"
          :key="item.frontID"
          :fields-data="item"
          :field-index="index"
          :files-for-remove="filesForRemove"
          :can-edit-application-data="canEditApplicationData"
          :can-edit-application-session="canEditApplicationSession"
          @fieldEdited="saveForm"
        />

        <div
          class="card-footer d-flex justify-content-between"
        >
          <div>
            <div
              v-if="stepResults && stepResults.step_status !== 'Draft'"
            >
              <b-button
                v-if="canApproveApplicationStep"
                variant="primary"
                class="mr-2"
                :disabled="stepResults.step_status == 'Approved'"
                @click="updStepStatus('Approved')"
              >
                Accept Step
              </b-button>
              <b-button
                v-if="canRejectApplicationStep"
                variant="danger"
                class="mr-3"
                @click="updStepStatus('Rejected')"
              >
                Reject
              </b-button>
            </div>
          </div>
        </div>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useApplicationDetails from '@/views/components/application-details/useApplicationDetails'

import FormResultEditRow from '@/views/admin/forms/form-result-edit/FormResultEditRow.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { removeFile, uploadFile } from '@/api/files'

export default {
  name: 'ApplicationDetails',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,

    FormResultEditRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    fieldsList: {
      type: Array,
      default: () => [],
    },
    defaultFieldsList: {
      type: Array,
      default: () => [],
    },
    fieldsValue: {
      type: Array,
      default: () => [],
    },
    stepResults: {
      type: Object,
      default: () => {},
    },
    step: {
      type: Object,
      default: () => {},
    },
    stepId: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filesForRemove: [],
      isAnyFieldEdited: false,
    }
  },
  setup() {
    const {
      editStepResults,
      postStepResults,
      updateStepStatus,
    } = useApplicationDetails()

    return {
      editStepResults,
      postStepResults,
      updateStepStatus,
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'app-application-edit/getApplicationId',
      type: 'app-application-edit/getType',
      isLoading: 'app-application-edit/getIsLoading',
    }),
    canEditApplicationData() {
      if (this.type === 'Staff') {
        return this.$can('update', permissionSubjects.StaffApplicationData)
      }
      return this.$can('update', permissionSubjects.ChildApplicationData)
    },
    canEditApplicationSession() {
      if (this.type === 'Staff') {
        return this.$can('update', permissionSubjects.StaffApplicationSession)
      }
      return this.$can('update', permissionSubjects.ChildApplicationSession)
    },
    canApproveApplicationStep() {
      if (this.type === 'Staff') {
        return this.$can('approve', permissionSubjects.StaffApplicationStep)
      }
      return this.$can('approve', permissionSubjects.ChildApplicationStep)
    },
    canRejectApplicationStep() {
      if (this.type === 'Staff') {
        return this.$can('reject', permissionSubjects.StaffApplicationStep)
      }
      return this.$can('reject', permissionSubjects.ChildApplicationStep)
    },
  },
  methods: {
    async updStepStatus(status) {
      this.$store.state['app-application-edit'].isLoading = true
      await this.updateStepStatus(this.stepResults.id, status).then(response => {
        if (response.status === 200 && response.step_status) {
          this.stepResults.step_status = response.step_status
        }
      })

      await this.$store.dispatch('app-application-edit/fetchApplication')

      this.$store.state['app-application-edit'].isLoading = false
    },
    fileUpload(file) {
      const formData = new FormData()
      formData.append('file', file)
      return uploadFile(`application-files-${new Date().getTime()}`, formData)
    },
    editSessions() {
      this.$bvModal.show('edit-sessions')
    },
    updateSessions() {
      // this.$router.back()
    },
    resetForm() {
      this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))
      this.$router.back()
    },
    async saveForm() {
      // Remove old files
      if (this.filesForRemove.length > 0) {
        this.filesForRemove.flat(Infinity).forEach(file => {
          removeFile({ file_path: file.path })
        })
      }
      const queryParams = {
        fields: [],
        form_data: {},
      }

      let fileFieldsCount = 0
      const filesLinks = []
      const filesNames = []

      const fileFields = this.fieldsList.filter(row => row.type === 'file_upload' && row.value)

      if (fileFields.length > 0) {
        fileFields.forEach(file => {
          Array.from(file.value).forEach(val => {
            filesLinks.push(this.fileUpload(val))
          })
        })
      }

      Promise.all(filesLinks)
        .then(responses => responses.forEach(
          response => {
            filesNames.push(response.file_path)
          },
        ))
        .finally(() => {
          this.fieldsList.forEach(row => {
            if (row.type === 'file_upload' && row.value) {
              queryParams.fields.push({
                id: row.id,
                field_id: row.field_id,
                files: filesNames.slice(fileFieldsCount, row.value.length),
              })
              queryParams.form_data[row.name] = filesNames.slice(fileFieldsCount, row.value.length)
              fileFieldsCount += 1
            } else {
              queryParams.fields.push({
                id: row.id,
                field_id: row.field_id,
                result: row.value,
              })
              queryParams.form_data[row.name] = row.value
            }
          })

          if (this.stepResults && this.stepResults.id) {
            this.editStepResults(this.stepResults.id, queryParams).then(() => {
              this.$store.dispatch('app-application-edit/fetchApplication')
            })
          } else {
            queryParams.application_step_id = this.stepId
            queryParams.application_id = this.applicationId
            this.postStepResults(queryParams).then(() => {
              this.$store.dispatch('app-application-edit/fetchApplication')
            })
          }
        })
    },
  },
}
</script>

<style scoped>
</style>
