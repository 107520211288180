import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  GetterTree, MutationTree, ActionTree,
} from 'vuex'
import { RootState } from '../types'
import { TagsState } from './types'
import { fetchTagSets, fetchTagSetsOptionsList } from '@/api/tags'

const state: TagsState = {
  tagSetOptionsList: [],
}

const getters: GetterTree<TagsState, RootState> = {
  getTagSetOptionsList: state => state.tagSetOptionsList,
}

const mutations: MutationTree<TagsState> = {
  SET_TAG_SET_OPTIONS_LIST(state, val) {
    state.tagSetOptionsList = val
  },
}

const actions: ActionTree<TagsState, RootState> = {
  async prepareTagSetsListOptions({ rootGetters, commit }, queryParams) {
    try {
      const response = await fetchTagSetsOptionsList(queryParams)
      commit('SET_TAG_SET_OPTIONS_LIST', response)
      return response
    } catch (error) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Tags list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    }
  },
  async fetchTagSetsList({ state }, queryParams) {
    try {
      const response = await fetchTagSets(queryParams)

      return response.data.data
    } catch (error) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Tag Sets list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
