import { render, staticRenderFns } from "./ViewCustomizer.vue?vue&type=template&id=374a0190&scoped=true&"
import script from "./ViewCustomizer.vue?vue&type=script&lang=js&"
export * from "./ViewCustomizer.vue?vue&type=script&lang=js&"
import style0 from "./ViewCustomizer.vue?vue&type=style&index=0&id=374a0190&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374a0190",
  null
  
)

export default component.exports