import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationEdit() {
  // Use toast
  const toast = useToast()

  const editStepResults = (ID, queryParams) => axios
    .put(`/auth/applications-step-result/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Step results was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating step results',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postStepResults = queryParams => axios
    .post('/auth/applications-step-result/', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Step results was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating step results',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const editStaffApplication = (ID, queryParams) => axios
    .put(`/auth/applications/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Application',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchApplication = ID => axios
    .get(`/auth/applications/${ID}/edit`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching application item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchProgram = ID => axios
    .get(`/auth/programs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchForm = ID => axios
    .get(`/auth/forms/${ID}`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const updateStepStatus = (id, status) => axios
    .post(`/auth/applications-step-result/${id}/status`, { status })
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Step status was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating status',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  return {
    editStepResults,
    postStepResults,
    editStaffApplication,
    fetchApplication,
    fetchProgram,
    fetchForm,
    updateStepStatus,
  }
}
