<template>
  <ValidationObserver
    ref="bulk-tag-assign"
  >
    <b-modal
      id="bulk-tag-assign-modal"
      ref="bulk-tag-assign-modal"
      centered
      :title="'Tag Assign (' + totalLength + ' selected)'"
      ok-title="Save"
      ok-only
      no-close-on-backdrop
      @hidden="closeModal"
      @show="prepareData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Selected Tags "
            >
              <b-form-tag
                v-for="tag in selectedTags"
                :key="tag.id"
                class="align-content-center mb-1"
                style="marginRight: 5px"
                :style="{
                  backgroundColor: '#F3F2F7',
                  color: tag.color,
                }"
                @remove="removeTag(tag)"
              >
                {{ tag.name }}
              </b-form-tag>
            </b-form-group>
          </b-col>

          <b-col
            v-if="withExecute"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Field with available application ID"
            >
              <validation-provider
                #default="{ errors }"
                name="destination field"
                rules="required"
              >
                <v-select
                  v-model="targetField"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinationTypeOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  :placeholder="'Select Field'"
                  :disabled="!destinationTypeOptions.length"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="handleTargetIdSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Select Tag Type"
            >
              <v-select
                v-model="selectedTagSet"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tagSetsList"
                :clearable="false"
                label="name"
                :placeholder="'Select Tag Type'"
                :disabled="!targetField && withExecute"
                @input="resetSelectedTags"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="selectedTagSet"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              v-if="selectedTagSet.select_type == 'Multiple'"
              label="Tags"
            >
              <v-select
                v-model="selectedTagNames"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="selectedTagSet.tags"
                :clearable="false"
                label="name"
                multiple
                :placeholder="'Select Tags'"
                @option:selecting="addTagName"
                @option:deselecting="removeTag"
              />
            </b-form-group>

            <b-form-group
              v-if="selectedTagSet.select_type == 'Radio'"
              label="Tags"
            >
              <template
                v-for="tag in selectedTagSet.tags"
              >
                <b-form-radio
                  :key="tag.id"
                  v-model="selectedTagNames"
                  :value="tag"
                  name="radio-options"
                  @change="addTagName"
                > {{ tag.name }}
                </b-form-radio>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading || !selectedTags.length"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormTag,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useBulkTagAssign from '@/views/components/bulk-tag-assign/useBulkTagAssign'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BFormTag,
    BFormRadio,
    vSelect,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    allElementsChecked: {
      type: Boolean,
      default: () => false,
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
    programId: {
      type: String,
      default: null,
    },
    totalElementsCount: {
      type: Number,
      default: () => 0,
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedTags: [],
      tagSetsList: [],
      selectedTagSet: [],
      selectedTagNames: null,
      isLoading: false,
      destinationTypeOptions: [],
      targetField: null,
      selectedApplications: [],
      totalLength: 0,
      required,
    }
  },
  setup() {
    const {
      fetchTagSets,
      assignTagsToApplications,
    } = useBulkTagAssign()

    const {
      fetchResultsByTargetField,
    } = useReportDetails()

    return {
      fetchTagSets,
      assignTagsToApplications,
      fetchResultsByTargetField,
    }
  },
  methods: {
    closeModal() {
      this.selectedApplications = []
      this.selectedTags = []
      this.selectedTagSet = []
      this.destinationTypeOptions = []
      this.targetField = null
      this.$refs['bulk-tag-assign-modal'].hide()
    },
    async elementsCount() {
      this.totalLength = this.allElementsChecked ? this.totalElementsCount : this.markedElements.length
    },
    async prepareData() {
      if (this.withExecute) {
        this.destinationTypeOptions = this.fieldsOptions
      }
      await this.prepareTagSetsList()
      await this.elementsCount()
    },
    async prepareTagSetsList() {
      const queryParams = {
        hasTags: true,
        program_id: this.programId,
      }
      if (this.filterName && !this.withExecute) {
        queryParams[this.filterName] = this.markedElements
      }

      this.tagSetsList = await this.$store.dispatch('tags/fetchTagSetsList', queryParams)
    },
    removeTag(tag) {
      const index = this.selectedTags.findIndex(item => item.id === tag.id)
      this.selectedTags.splice(index, 1)
    },
    resetSelectedTags() {
      this.selectedTagNames = null
      if (this.selectedTagSet.select_type === 'Radio') {
        this.selectedTagNames = this.selectedTagSet.tags.find(tag => this.selectedTags.some(selectedTag => tag.id === selectedTag.id))
      } else if (this.selectedTagSet.select_type === 'Multiple') {
        this.selectedTagNames = this.selectedTagSet.tags.filter(tag => this.selectedTags.find(selectedTag => tag.id === selectedTag.id))
      }
    },
    addTagName(tag) {
      if (this.selectedTagSet.select_type === 'Radio') {
        const oldTag = this.selectedTags.find(item => item.tag_set_id === (this.selectedTagSet.id))
        if (oldTag) {
          this.removeTag(oldTag)
        }
      }
      if (!this.selectedTags.some(item => item.id === tag.id)) {
        this.selectedTags.push(tag)
      }
    },
    async handleTargetIdSelected() {
      if (this.allElementsChecked) {
        const queryParams = {
          executeData: this.executeData,
          targetField: this.targetField,
        }
        if (this.programId) {
          queryParams.program_id = this.programId
        }
        this.selectedApplications = await this.fetchResultsByTargetField(queryParams)
          .then(response => response.data)
      } else {
        this.selectedApplications = this.markedElements.map(element => element[this.targetField])
      }
      await this.elementsCount()
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['bulk-tag-assign'].validate().then(success => {
          if (success) {
            resolve(true)
            this.assignTags()
          } else {
            reject()
          }
        })
      })
    },
    async assignTags() {
      const queryParams = {
        tag_ids: this.selectedTags.map(tag => tag.id),
      }
      if (this.programId) {
        queryParams.program_id = this.programId
      }
      if (this.allElementsChecked && this.groupId) {
        queryParams.group_id = this.groupId
      } else if (this.filterName) {
        queryParams[this.filterName] = this.withExecute ? this.selectedApplications : this.markedElements
      }
      this.isLoading = true
      await this.assignTagsToApplications(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
