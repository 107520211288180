import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCamperProgramProfile() {
  // Use toast
  const toast = useToast()

  const fetchApplicationFiles = ID => axios
    .get(`/auth/applications/${ID}/files`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching application files',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchApplicationFiles,
  }
}
