<template>
  <v-select
    v-model="value"
    class="w-100"
    :class="{
      'multiple-select': isMultiple,
      'select': !isMultiple,
    }"
    placeholder="Select"
    :disabled="isDisabledField"
    :multiple="isMultiple"
    :deselect-from-dropdown="isMultiple"
    :close-on-select="!isMultiple"
    :searchable="true"
    :options="preparedOptions"
    :selectable="option => isSelectable(option)"
    label="name"
    :loading="isLoading"
    @input="emitChanges"
  >
    <template #no-options>
      There is no options for {{ fieldsData.label }}
    </template>

    <template
      #option="{ name, price, max_capacity, member_count }"
      :class="price"
    >
      <span>{{ name }} <i v-if="max_capacity && max_capacity <= member_count">(full)</i> </span>

      <span v-if="price === 0" />
      <template v-else-if="!hidePrice">
        <span>
          <b v-if="price > 0">+</b><b v-if="price < 0">-</b>${{ price | toFixedNumber }}
        </span>
      </template>

    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import { fetchApplicationGroupOptions } from '@/api/applications'

export default {
  components: {
    vSelect,
  },
  filters: {
    toFixedNumber(value) {
      return value.toFixed(2).replace('-', '')
    },
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {
      },
    },
    isDisabledField: {
      type: Boolean,
      default: false,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      options: [],
      applicationId: this.$router.currentRoute.params.id,
      isLoading: false,
    }
  },
  computed: {
    preparedOptions() {
      return this.options.reduce((options, group) => {
        if (group.is_visible) {
          /* eslint-disable no-param-reassign */
          group.price = group.sessions.reduce((totalPrice, session) => {
            totalPrice += session.pivot_price
            return totalPrice
          }, 0)
          options.push(group)
          return options
        }
        return options
      }, [])
    },
    isMultiple() {
      return true
    },
  },
  async mounted() {
    await this.prepareGroupOptions()
    this.value = this.fieldsData.value
  },
  methods: {
    isCompatibleForSelect(newOption) {
      if (this.value == null) {
        return true
      }

      if (this.value.length === 0) {
        return true
      }
      return !this.value[0].is_restrict_single_group_type && !newOption.is_restrict_single_group_type
    },
    isSelectable(option) {
      return option.is_allow_use_selection && this.isNotFullGroup(option) && this.isCompatibleForSelect(option)
    },
    isNotFullGroup(option) {
      if (!option.max_capacity) {
        return true
      }
      return !option.is_capacity_enforcement || (option.is_capacity_enforcement && option.max_capacity > option.member_count)
    },
    emitChanges() {
      let value
      if (Array.isArray(this.value)) {
        value = this.value.map(item => ({ id: item.id, name: item.name }))
      } else {
        value = { id: this.value?.id, name: this.value.name }
      }
      this.fieldsData.groups = value
    },
    async prepareGroupOptions() {
      this.isLoading = true
      const queryParams = {
        group_type: this.fieldsData.default,
      }

      await fetchApplicationGroupOptions(this.applicationId, queryParams).then(res => {
        this.options = res.data.data
      })

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
.group-type-label {
  margin-bottom: 10px;
}

.is-invalid .vs__dropdown-toggle {
  border: 1px solid red;
}
</style>
