import axios from '@axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    boardViewType: localStorage.getItem('applicationsBoardViewType') ? JSON.parse(localStorage.getItem('applicationsBoardViewType')) : 'List',
    selectedViewColumns: localStorage.getItem('applicationsTableColumns') ? JSON.parse(localStorage.getItem('applicationsTableColumns')).filter(col => col.selected) : [],
    selectedViewTags: localStorage.getItem('applicationsTagsView') ? JSON.parse(localStorage.getItem('applicationsTagsView')) : [],
    storageTableColumns: localStorage.getItem('applicationsTableColumns') ? JSON.parse(localStorage.getItem('applicationsTableColumns')) : [],
    storageSortBy: localStorage.getItem('applicationsSortBy') ? JSON.parse(localStorage.getItem('applicationsSortBy')) : 'user_name',
    storageIsSortByDesc: localStorage.getItem('applicationsIsSortByDesc') ? (JSON.parse(localStorage.getItem('applicationsIsSortByDesc')) === true) : false,
    deletableApplication: null,
    forceReload: false,
    markedElements: [],
    statusesList: [],
    generateLetterForApplication: null,
    applicationIdForCommunicate: null,
    applicationIdForSharing: null,
    filterDataPaymentStatus: '',
    filterDataStatus: '',
    filterDataStep: '',
    filterDataGrade: '',
    filterDataSession: null,
    filterDataLockedStatus: null,
    applicationsType: '',
    searchQuery: '',
    showFilterDetails: false,
    showOverviewStatistics: true,
    allApplicationsByStatuses: [],
    stepsList: [],
    gradesList: [],
    sessionsList: [],
    filterLockedStatusOptions: [
      {
        label: 'Locked',
        value: 'locked',
      },
      {
        label: 'Unlocked',
        value: 'unlocked',
      },
    ],
  },
  getters: {
    getBoardViewType: state => state.boardViewType,
    getSelectedViewColumns: state => state.selectedViewColumns,
    getSelectedViewTags: state => state.selectedViewTags,
    getStorageTableColumns: state => state.storageTableColumns,
    getStorageSortBy: state => state.storageSortBy,
    getStorageIsSortByDesc: state => state.storageIsSortByDesc,
    getDeletableApplication: state => state.deletableApplication,
    getForceReload: state => state.forceReload,
    getMarkedElements: state => state.markedElements,
    getStatusesList: state => state.statusesList,
    getGenerateLetterForApplication: state => state.generateLetterForApplication,
    getApplicationIdForCommunicate: state => state.applicationIdForCommunicate,
    getApplicationIdForSharing: state => state.applicationIdForSharing,
    getFilterDataPaymentStatus: state => state.filterDataPaymentStatus,
    getFilterDataStatus: state => state.filterDataStatus,
    getFilterDataStep: state => state.filterDataStep,
    getFilterDataGrade: state => state.filterDataGrade,
    getFilterDataSession: state => state.filterDataSession,
    getFilterDataLockedStatus: state => state.filterDataLockedStatus,
    getFilterLockedStatusOptions: state => state.filterLockedStatusOptions,
    getApplicationsType: state => state.applicationsType,
    getShowFilterDetails: state => state.showFilterDetails,
    getShowOverviewStatistics: state => state.showOverviewStatistics,
    getSearchQuery: state => state.searchQuery,
    getAllApplicationsByStatuses: state => state.allApplicationsByStatuses,
    getStepsList: state => state.stepsList,
    getGradesList: state => state.gradesList,
    getSessionsList: state => state.sessionsList,
  },
  mutations: {
    SET_BOARD_VIEW_TYPE: (state, val) => {
      state.boardViewType = val
      localStorage.setItem('applicationsBoardViewType', JSON.stringify(val))
    },
    SET_SELECTED_VIEW_COLUMNS: (state, val) => {
      state.selectedViewColumns = val.filter(col => col.selected)
      state.storageTableColumns = val
      localStorage.setItem('applicationsTableColumns', JSON.stringify(val))
    },
    SET_SELECTED_VIEW_TAGS: (state, val) => {
      state.selectedViewTags = val
      localStorage.setItem('applicationsTagsView', JSON.stringify(val))
    },
    SET_STORAGE_SORT_BY: (state, val) => {
      state.storageSortBy = val
      localStorage.setItem('applicationsSortBy', JSON.stringify(val))
    },
    SET_STORAGE_IS_SORT_BY_DESC: (state, val) => {
      state.storageIsSortByDesc = val
      localStorage.setItem('applicationsIsSortByDesc', JSON.stringify(val))
    },
    SET_DELETABLE_APPLICATION: (state, val) => {
      state.deletableApplication = val
    },
    SET_FORCE_RELOAD: (state, val) => {
      state.forceReload = val
    },
    SET_MARKED_ELEMENTS: (state, val) => {
      state.markedElements = val
    },
    ADD_ELEMENT: (state, val) => {
      if (!state.markedElements.includes(val)) {
        state.markedElements.push(val)
      }
    },
    SET_STATUSES_LIST: (state, val) => {
      state.statusesList = val
    },
    SET_GENERATE_LETTER_FOR_APPLICATION: (state, val) => {
      state.generateLetterForApplication = val
    },
    SET_APPLICATION_ID_FOR_COMMUNICATE: (state, val) => {
      state.applicationIdForCommunicate = val
    },
    SET_APPLICATION_ID_FOR_SHARING: (state, val) => {
      state.applicationIdForSharing = val
    },
    SET_FILTER_DATA_STATUS: (state, val) => {
      state.filterDataStatus = val
    },
    SET_FILTER_DATA_STEP: (state, val) => {
      state.filterDataStep = val
    },
    SET_FILTER_DATA_GRADE: (state, val) => {
      state.filterDataGrade = val
    },
    SET_FILTER_DATA_SESSION: (state, val) => {
      state.filterDataSession = val
    },
    SET_FILTER_DATA_LOCKED_STATUS: (state, val) => {
      state.filterDataLockedStatus = val
    },
    SET_APPLICATIONS_TYPE: (state, val) => {
      state.applicationsType = val
    },
    SET_SHOW_FILTER_DETAILS: (state, val) => {
      state.showFilterDetails = val
    },
    SET_SHOW_OVERVIEW_STATISTICS: (state, val) => {
      state.showOverviewStatistics = val
    },
    SET_FILTER_DATA_PAYMENT_STATUS: (state, val) => {
      state.filterDataPaymentStatus = val
    },
    SET_SEARCH_QUERY: (state, val) => {
      state.searchQuery = val
    },
    SET_FILTER_PARAMS: state => {
      const query = { ...router.currentRoute.query }
      state.searchQuery = query.searchQuery || ''
      state.filterDataStatus = query.filterDataStatus || ''
      state.filterDataPaymentStatus = query.filterDataPaymentStatus || ''
      state.filterDataStep = query.filterDataStep || ''
      state.filterDataGrade = query.filterDataGrade || ''
      state.filterDataSession = query.filterDataSession || ''
      state.filterDataLockedStatus = query.filterDataLockedStatus || ''
      state.applicationsType = router.currentRoute.params.type || ''
    },
    CLEAR_FILTERS: state => {
      state.searchQuery = ''
      state.filterDataStatus = ''
      state.filterDataPaymentStatus = ''
      state.filterDataStep = ''
      state.filterDataGrade = ''
      state.filterDataSession = ''
      state.filterDataLockedStatus = ''
      state.applicationsType = ''
    },
    UPDATE_ALL_APPLICATIONS_BY_STATUSES: (state, val) => {
      state.allApplicationsByStatuses.push(...val)
    },
    RESET_ALL_APPLICATIONS_BY_STATUSES: state => {
      state.allApplicationsByStatuses = []
    },
    SET_STEPS_LIST: (state, val) => {
      state.stepsList = val
    },
    SET_GRADES_LIST: (state, val) => {
      state.gradesList = val
    },
    SET_SESSIONS_LIST: (state, val) => {
      state.sessionsList = val
    },
  },
  actions: {
    fetchApplicationList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/v2/applications', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchApplicationStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/registration-form-statuses', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchApplicationStatusesStatistics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/registration-form-statuses-statistics', {
            params: queryParams,
          })
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    fetchProgramList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/programs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchApplicationsByStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications-by-statuses', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStepsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/steps', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGradesList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const query = {
          ...queryParams,
          name: 'grade',
        }
        axios
          .get('/auth/ppa/values', {
            params: query,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSessionsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/sessions-for-select', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
