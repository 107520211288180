var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"w-100",class:{
    'multiple-select': _vm.isMultiple,
    'select': !_vm.isMultiple,
  },attrs:{"placeholder":"Select","disabled":_vm.isDisabledField,"multiple":_vm.isMultiple,"deselect-from-dropdown":_vm.isMultiple,"close-on-select":!_vm.isMultiple,"searchable":true,"options":_vm.preparedOptions,"selectable":function (option) { return _vm.isSelectable(option); },"label":"name","loading":_vm.isLoading},on:{"input":_vm.emitChanges},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" There is no options for "+_vm._s(_vm.fieldsData.label)+" ")]},proxy:true},{key:"option",fn:function(ref){
  var name = ref.name;
  var price = ref.price;
  var max_capacity = ref.max_capacity;
  var member_count = ref.member_count;
return [_c('span',[_vm._v(_vm._s(name)+" "),(max_capacity && max_capacity <= member_count)?_c('i',[_vm._v("(full)")]):_vm._e()]),(price === 0)?_c('span'):(!_vm.hidePrice)?[_c('span',[(price > 0)?_c('b',[_vm._v("+")]):_vm._e(),(price < 0)?_c('b',[_vm._v("-")]):_vm._e(),_vm._v("$"+_vm._s(_vm._f("toFixedNumber")(price))+" ")])]:_vm._e()]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }