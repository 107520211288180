

























































import Vue from 'vue'

import {
  BModal,
  BOverlay,
  BvModalEvent,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { appGroupBulkEdit } from '@/store/modules/app-groups/app-group-bulk-edit'
import GroupBulkEditGeneralSettings from '@/views/admin/group/group-forms/components/GroupBulkEditGeneralSettings.vue'
import GroupBulkEditIndividualSettings
  from '@/views/admin/group/group-forms/components/GroupBulkEditIndividualSettings.vue'

interface GroupBulkEditModal {
  validationErrors: any[],
}
export default Vue.extend({
  name: 'GroupBulkEditModal',
  components: {
    GroupBulkEditIndividualSettings,
    GroupBulkEditGeneralSettings,
    BOverlay,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    markedGroups: {
      type: Array,
      required: true,
    },
  },
  data(): GroupBulkEditModal {
    return {
      validationErrors: [],
    }
  },
  computed: {
    isLoading() {
      return appGroupBulkEdit.getters.getIsLoading
    },
    groups() {
      return appGroupBulkEdit.getters.getItems
    },
  },
  methods: {
    async setModalData() {
      await this.prepareGroupsData()
    },
    async prepareGroupsData() {
      appGroupBulkEdit.mutations.SET_IS_LOADING(true)
      appGroupBulkEdit.mutations.SET_SELECTED_GROUPS_IDS(this.markedGroups)
      await appGroupBulkEdit.actions.fetchGroupsList()
      await appGroupBulkEdit.actions.prepareGroupCustomFields()
      await appGroupBulkEdit.actions.prepareGroupAutoAssignRules()
      await appGroupBulkEdit.actions.prepareGroupTypeInfo()
      appGroupBulkEdit.mutations.SET_IS_LOADING(false)
    },
    async handleForm(bvModalEvt: BvModalEvent) {
      this.validationErrors = []
      bvModalEvt.preventDefault()
      await appGroupBulkEdit.actions.saveGroupChanges()
        .then(response => {
          if (response.status === 422) {
            const validationErrors = Object.values(response.data.errors)
            this.validationErrors = validationErrors.flat()
          }
        })
    },
    async resetModal() {
      await appGroupBulkEdit.actions.cleanData()
    },
  },
})
