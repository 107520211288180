<template>
  <validation-observer
    ref="groupTypeAutoAssignRules"
    tag="form"
  >
    <b-list-group-item
      tag="div"
      class="assign-area"
    >
      <b-row>
        <b-col cols="12">
          <h6 class="d-flex justify-content-between align-items-center">
            <span>
              Rule {{ index + 1 }}
            </span>
            <feather-icon
              size="18"
              icon="Trash2Icon"
              class="cursor-pointer text-primary"
              @click="removeRow()"
            />
          </h6>
          <hr>
        </b-col>

        <b-col cols="12">
          <!-- Student PPA -->
          <b-form-group
            :label="`${camperStudent(1)} PPA`"
          >
            <validation-provider
              #default="{ errors }"
              :name="`${camperStudent(1)} PPA`"
              rules="required"
            >
              <v-select
                v-model="assignData.studentPpa"
                class="bg-white"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="studentPpaList"
                :placeholder="'Select'"
                :clearable="false"
                :class="{'is-invalid': errors.length > 0 }"
                @input="$emit('change')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Operator -->
          <b-form-group
            label="Operator"
          >
            <validation-provider
              #default="{ errors }"
              name="Operator"
              rules="required"
            >
              <v-select
                v-model="assignData.operator"
                class="bg-white"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="operatorsOptions"
                :placeholder="'Select'"
                :clearable="false"
                label="text"
                :reduce="option => option.value"
                :class="{'is-invalid': errors.length > 0 }"
                @input="$emit('change')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Selected field  -->
          <b-form-group
            label="Selected field"
          >
            <validation-provider
              #default="{ errors }"
              name="Selected field"
              rules="required"
            >
              <v-select
                v-model="assignData.customField"
                class="bg-white"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customFieldsList"
                :placeholder="'Select'"
                :clearable="false"
                label="label"
                :multiple="isValueMultiselect"
                :reduce="option => option.id"
                :class="{'is-invalid': errors.length > 0 }"
                @input="$emit('change')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>
    </b-list-group-item>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useCustomField from '@/views/admin/group/group-forms/components/useCustomField'
// import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormGroup,
    BListGroupItem,

    // SquareIcon,
    vSelect,
  },
  props: {
    assignData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    customFieldsList: {
      type: Array,
      required: true,
    },
    studentPpaList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      operatorsOptions: [
        { value: 'equals', text: 'equals' },
        { value: 'does_not_equal', text: 'does not equal' },
        { value: 'greater_than', text: 'greater than' },
        { value: 'greater_than_or_equal', text: 'greater than or equal' },
        { value: 'less_than', text: 'less than' },
        { value: 'less_than_or_equal', text: 'less than or equal' },
        { value: 'like', text: 'like' },
        { value: 'not_like', text: 'not like' },
        { value: 'contains_all', text: 'contains all' },
        { value: 'contains_one', text: 'contains at least one' },
        { value: 'does_not_contain_all', text: 'does not contain all' },
        { value: 'does_not_contain_one', text: 'does not contain at least one' },
        { value: 'is_at', text: 'is at' },
        { value: 'is_before', text: 'is before' },
        { value: 'is_before_or_at', text: 'is before or at' },
        { value: 'is_after', text: 'is after' },
        { value: 'is_after_or_at', text: 'is after or at' },
        { value: 'is_not_at', text: 'is not at' },
        { value: 'is_blank', text: 'is blank' },
        { value: 'is_not_blank', text: 'is not blank' },
        { value: 'is_yesterday', text: 'is yesterday' },
        { value: 'is_today', text: 'is today' },
        { value: 'is_this_week', text: 'is this week' },
        { value: 'is_this_month', text: 'is this month' },
        { value: 'is_this_year', text: 'is this year' },
        { value: 'is_last_month', text: 'is last month' },
        { value: 'is_next_month', text: 'is next month' },
      ],
    }
  },
  setup() {
    const {
      fieldTypes,
    } = useCustomField()

    return {
      fieldTypes,
    }
  },
  computed: {
    operatorsWithMultiselectValue() {
      return [
        'contains_all',
        'contains_one',
        'does_not_contain_all',
        'does_not_contain_one',
      ]
    },
    isValueMultiselect() {
      return this.operatorsWithMultiselectValue.includes(this.assignData.operator)
    },
  },
  methods: {
    removeRow() {
      this.$emit('removeAssignsRow', this.index)
    },
  },
}
</script>

<style scoped>
  .assign-area {
    background: #FBFBFC;
  }
</style>
