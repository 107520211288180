import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { fetchTagSetsOptionsList } from '@/api/tags'

export default {
  namespaced: true,
  state: {
    groupId: null,
    groupDetail: {
      name: null,
      type: null,
      status: null,
      program: null,
      program_id: null,
      member_count: null,
      type_id: null,
      parent_group: {},
      customFields: [],
      groupTypeCustomFields: [],
      assignsList: [],
      sessions: [],
      adminRules: {},
    },
    isLoading: true,
    groupMainInfo: {
      status: '',
    },
    programId: '',
    groupSessionsOptions: [],
    allGroupsListForMoving: [{ value: null }],
    applicationsForAutoAssign: [],
    tagSetOptionsList: [],
    tagStatistics: [],
    groupTypeFieldsList: [],
    groupCustomFieldsList: [],
    ppaList: [],
  },
  getters: {
    getGroupId: state => state.groupId,
    getGroupDetail: state => state.groupDetail,
    getIsLoading: state => state.isLoading,
    getGroupMainInfo: state => state.groupMainInfo,
    getProgramId: state => state.programId,
    getGroupSessionsOptions: state => state.groupSessionsOptions,
    getAllGroupsListForMoving: state => state.allGroupsListForMoving,
    getApplicationsForAutoAssign: state => state.applicationsForAutoAssign,
    getTagSetOptionsList: state => state.tagSetOptionsList,
    getTagStatistics: state => state.tagStatistics,
    getGroupTypeFieldsList: state => state.groupTypeFieldsList,
    getGroupCustomFieldsList: state => state.groupCustomFieldsList,
    getPpaList: state => state.ppaList,
  },
  mutations: {
    SET_GROUP_ID: (state, val) => {
      state.groupId = val
    },
    SET_LOADING_STATUS: (state, val) => {
      state.isLoading = val
    },
    SET_GROUP_DATA(state, response) {
      state.groupDetail.name = response.name
      state.groupDetail.type = response.type
      state.groupDetail.status = response.status
      state.groupDetail.program = response.program
      state.groupDetail.program_id = response.program_id
      state.groupDetail.member_count = response.member_count
      state.groupDetail.type_id = response.type_id
      state.groupDetail.parent_group = response.parent_group
      state.groupDetail.customFields = response.custom_fields
      state.groupDetail.groupTypeCustomFields = response.custom_type_fields
      state.groupDetail.assignsList = response.auto_assigns || []
      state.groupDetail.sessions = response.sessions.map(session => ({
        ...session,
        start_price: session.pivot_price,
      }))
      state.groupDetail.adminRules = response.settings
      state.groupMainInfo = JSON.parse(JSON.stringify(state.groupDetail))
      state.programId = response.program_id

      this.commit('app-group-details/UPDATE_CUSTOM_FIELDS')

      state.isLoading = false
    },
    UPDATE_CUSTOM_FIELDS: state => {
      state.groupCustomFieldsList = state.groupDetail.customFields.filter(form => form.group_type_custom_field_id === null)
      state.groupTypeFieldsList = state.groupDetail.groupTypeCustomFields
      state.groupDetail.customFields.filter(form => form.group_type_custom_field_id !== null).forEach(groupField => {
        const out = state.groupTypeFieldsList.findIndex(groupTypeField => groupField.group_type_custom_field_id === groupTypeField.group_type_custom_field_id)
        if (out > -1) {
          state.groupTypeFieldsList[out] = groupField
        }
      })
    },
    RESET_GROUP_MODAL_DATA(state) {
      state.groupDetail = JSON.parse(JSON.stringify(state.groupMainInfo))
      this.commit('app-group-details/UPDATE_CUSTOM_FIELDS')
    },
    SET_GROUP_SESSIONS_OPTIONS: (state, val) => {
      state.groupSessionsOptions = val
    },
    SET_ALL_GROUPS_LIST_FOR_MOVING: (state, val) => {
      state.allGroupsListForMoving = val
    },
    SET_APPLICATIONS_FOR_GROUP_AUTO_ASSIGN: (state, val) => {
      state.applicationsForAutoAssign = val
    },
    SET_TAG_SET_OPTIONS_LIST: (state, val) => {
      state.tagSetOptionsList = val
    },
    SET_TAG_STATISTICS: (state, val) => {
      state.tagStatistics = val
    },
    SET_PPA_LIST: (state, val) => {
      state.ppaList = val
    },
  },
  actions: {
    async fetchGroup({ state, commit }) {
      try {
        const response = await axios.get(`/auth/groups/${state.groupId}`)

        commit('SET_GROUP_DATA', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchSessions({ state, commit }) {
      try {
        const response = await axios.get('/auth/sessions', {
          params: { programId: state.programId },
        })

        commit('SET_GROUP_SESSIONS_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchGroupsForMoving({ state, commit }) {
      try {
        const response = await axios.get('/auth/groups', {
          params: {
            exceptGroupId: state.groupId,
            programId: state.programId,
          },
        })

        const data = response.data.data.reduce((arr, item) => {
          arr.push({ value: item.id, text: item.name })
          return arr
        }, [])
        commit('SET_ALL_GROUPS_LIST_FOR_MOVING', data)
        return response
      } catch (error) {
        return error
      }
    },
    fetchLetterTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/letter-templates', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchApplicationsForGroupAutoAssign({ state, commit }) {
      try {
        const response = await axios.get('/auth/applications-for-group-auto-assign', {
          params: {
            group_id: state.groupId,
            program_id: state.programId,
          },
        })

        commit('SET_APPLICATIONS_FOR_GROUP_AUTO_ASSIGN', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async updateGroup({ state, commit }, {
      isCustomPricingAvailable, changedPriceData, customFields, vm,
    }) {
      try {
        commit('SET_LOADING_STATUS', true)

        const formData = {
          id: state.groupId,
          name: state.groupDetail.name,
          status: state.groupDetail.status,
          group_settings: state.groupDetail.adminRules,
          auto_assigns: state.groupDetail.assignsList,
          custom_fields: customFields,
        }

        if (!isCustomPricingAvailable) {
          formData.sessions = state.groupDetail.sessions.map(session => ({
            id: session.id,
            price: 0,
          }))
        } else {
          formData.sessions = state.groupDetail.sessions.map(session => {
            let chosenChargeOption = null

            let changedSession = changedPriceData.increase.groupSessions.find(item => item.id === session.id)
            if (changedSession) {
              chosenChargeOption = changedPriceData.increase.chosenOption
            } else {
              changedSession = changedPriceData.decrease.groupSessions.find(item => item.id === session.id)
              if (changedSession) {
                chosenChargeOption = changedPriceData.decrease.chosenOption
              }
            }

            return {
              id: session.id,
              price: session.pivot_price ? session.pivot_price : 0,
              charge_option: chosenChargeOption,
              difference: changedSession?.difference,
            }
          })
        }

        const response = await axios.put(`/auth/groups/${state.groupId}`, formData)

        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Group was successfully updated',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        commit('SET_LOADING_STATUS', false)
        commit('SET_GROUP_DATA', response.data.data)
        vm.$bvModal.hide('edit-group')
      } catch (error) {
        commit('SET_LOADING_STATUS', false)
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating group',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async updateUserVisibility({ state, commit }, { queryParams, vm }) {
      try {
        commit('SET_LOADING_STATUS', true)

        await axios.post(`/auth/groups/${state.groupId}/update-application`, queryParams)

        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'User Visibility was successfully updated',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        commit('SET_LOADING_STATUS', false)
        vm.$bvModal.hide('change-status')
      } catch (error) {
        commit('SET_LOADING_STATUS', false)
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating User Visibility',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async prepareTagSetsListOptions({ commit, rootGetters }) {
      try {
        const response = await fetchTagSetsOptionsList({
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        })

        commit('SET_TAG_SET_OPTIONS_LIST', response)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchTagStatistics({ commit }, { queryParams }) {
      try {
        const response = await axios.post('/auth/tags-statistics', queryParams)

        commit('SET_TAG_STATISTICS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    fetchAllMemberList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async updateCustomFields({ state, commit }, { queryParams, vm }) {
      try {
        const response = await axios.post(`/auth/groups/${state.groupId}/fields`, queryParams)

        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Group Custom Fields was successfully updated',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        commit('SET_GROUP_DATA', response.data.data)
        return response
      } catch (error) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating Group Custom Fields',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
    fetchGroupList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/groups', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypesForSelect(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types-for-select', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/group-types/${queryParams.id}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    fetchParentGroup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/groups-by-type/${queryParams.id}`, {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupMemberList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/campers', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStaffList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/staffs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCampersForSelectList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/campers-for-select', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportCampers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/campers/export-with-group', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupsCustomFieldsAttributes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/group-custom-fields', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveBulkGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/save-bulk-groups', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importBulkAssignGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/groups-bulk-assign', formData)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    importBulkCreateGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/groups-bulk-create', formData)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    exportGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/groups-export', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cloneGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/auth/groups/${data.group_id}/clone`, { copy_members: data.copy_members })
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    async fetchPpaList({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/ppa/names', {
          params: queryParams,
        })

        commit('SET_PPA_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
