import axios from '@axios'

export const fetchGroupsForSelect = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/groups-for-select', { params })
  return res
}
export const fetchGroups = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/groups', { params })
  return res
}
export const saveBulkGroupChanges = async (params?: any): Promise<any> => {
  const res = await axios.post('/auth/save-bulk-groups', params)
  return res
}

export const fetchGroupCustomFields = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/group-custom-fields-for-select', { params })
  return res
}

export const fetchGroupAutoAssignRules = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/group-auto-assign-rules', { params })
  return res
}
export const deleteGroup = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/groups/${id}`)
  return res
}

export const fetchGroupsForExport = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/groups-export', { params })
  return res
}
export const downloadBadges = async (params?: any): Promise<any> => {
  const res = await axios.post('/auth/download-badges', params)
  return res
}
export const fetchGroupsUsers = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/groups-users', { params })
  return res
}

export const cloneGroup = async (id: string, params?: any): Promise<any> => {
  const res = await axios.post(`auth/groups/${id}/clone`, { copy_members: params.copy_members })
  return res
}

export const fetchGroupsUsersExtraDetails = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/groups/extra/details', { params })
  return res
}

export const _ = null
