<template>

  <b-row>
    <!-- Right Col: -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <ApplicationAddRow
          v-for="(item, index) in fieldsList"
          :key="item.frontID"
          :fields-data="item"
          :field-index="index"
        />

        <div
          class="card-footer"
        >
          <b-button
            variant="primary"
            class="mr-2"
            @click="saveForm"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-primary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
        </div>
      </b-card>

    </b-col>

  </b-row>

</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/staff/applications/applicationsStoreModule'
import useApplicationAdd from '@/views/staff/applications/application-add/useApplicationAdd'
import router from '@/router'

import ApplicationAddRow from '@/views/staff/applications/application-add/ApplicationAddRow.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ApplicationAddRow,
  },
  directives: {
    Ripple,
  },
  mixins: [formBuilderPreparations],
  data() {
    return {
      groupDetail: {},
      setProgram: null,
      type: 'Staff',
      fieldsList: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-applications'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchProgram,
      fetchForm,
      postStaffApplication,
    } = useApplicationAdd()

    return {
      fetchProgram,
      fetchForm,
      postStaffApplication,
    }
  },
  watch: {
    setProgram(val) {
      if (!val) return

      this.fetchForm(val.form_id)
        .then(response => {
          this.fieldsList = response.fields.map(row => ({
            id: row.id,
            value: this.designateValue(row.type, row.default),
            label: row.label,
            name: row.name,
            options: this.designateOptions(row.type, row.options),
            type: this.transformField(row.type),
            required: row.required,
          }))
        })
    },
  },
  async created() {
    const programID = router.currentRoute.params.id
    if (programID) {
      await this.fetchProgram(programID).then(response => {
        this.setProgram = response
        this.setProgram.form_id = response.forms.filter(form => form.type === this.type)[0]?.id
      })
    }
  },
  methods: {
    cancelForm() {
      return 'cancelForm'
    },
    async saveForm() {
      const queryParams = {
        form_id: this.setProgram.form_id,
        program_id: router.currentRoute.params.id,
        fields: [],
        form_data: {},
      }

      this.fieldsList.forEach(row => {
        queryParams.fields.push({
          field_id: row.id,
          result: row.value,
        })
        queryParams.form_data[row.name] = row.value
      })

      await this.postStaffApplication(queryParams)
    },
  },
}
</script>

<style scoped>
</style>
