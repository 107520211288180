import { ref, watch, computed } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { fetchFilesByFolder } from '@/api/files'

export default function useFileFolderDetails() {
  // Use toast
  const toast = useToast()

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const fileFolderName = router.currentRoute.params.name
  const fileFolderLabel = router.currentRoute.params.label

  const isLoading = ref(false)
  const searchQuery = ref('')
  const applicationsList = ref([])

  const fetchFileFolderDetails = () => {
    const queryParams = {
      program_id: programId.value,
    }
    if (searchQuery.value) queryParams.user_name = searchQuery.value
    isLoading.value = true

    fetchFilesByFolder(fileFolderName, queryParams)
      .then(response => {
        applicationsList.value = response.data.data
      })
      .catch(() => toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Files in the Folder',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      }))
      .finally(() => {
        isLoading.value = false
      })
  }

  watch([programId, searchQuery], () => {
    fetchFileFolderDetails()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchFileFolderDetails,
    isLoading,
    searchQuery,
    applicationsList,
    fileFolderLabel,
  }
}
