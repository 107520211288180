




































































































































































































































































































































































































import Vue from 'vue'

import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BRow,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'
import vSelect from 'vue-select'
import { appGroupBulkEdit } from '@/store/modules/app-groups/app-group-bulk-edit'
import GroupCustomFieldRowFromType from '@/views/admin/group/group-forms/components/GroupCustomFieldRowFromType.vue'
import GroupCustomFields from '@/views/admin/group/group-forms/components/GroupCustomFields.vue'
import GroupTypeAutoAssigns from '@/views/admin/group/group-forms/components/GroupTypeAutoAssigns.vue'
import GroupAdminRulesComponent from '@/views/admin/group/group-forms/components/GroupAdminRulesComponent.vue'
import { IGeneralSettingsState } from '@/store/modules/app-groups/app-group-bulk-edit/types'

interface GroupBulkEditGeneralSettingsData {
  visible: boolean,
  collapseItemID: string,
  openOnHover: boolean,
}

export default Vue.extend({
  name: 'GroupBulkEditGeneralSettings',
  components: {
    GroupAdminRulesComponent,
    GroupTypeAutoAssigns,
    GroupCustomFields,
    GroupCustomFieldRowFromType,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  data(): GroupBulkEditGeneralSettingsData {
    return {
      visible: false,
      collapseItemID: uuidv4(),
      openOnHover: (this.$parent as any).hover,
    }
  },
  computed: {
    generalSettings: {
      get(): IGeneralSettingsState {
        return appGroupBulkEdit.getters.getGeneralSettings
      },
      set(val: IGeneralSettingsState) {
        appGroupBulkEdit.mutations.SET_GENERAL_SETTINGS(val)
      },
    },
    groupStatusOptions() {
      return appGroupBulkEdit.getters.getGroupStatusOptions
    },
    groupSessionsOptions() {
      return appGroupBulkEdit.getters.getSessionsList
    },
    groupTypeFieldsList() {
      return appGroupBulkEdit.getters.getGroupTypeFieldsList
    },
    groupFieldsList() {
      return appGroupBulkEdit.getters.getGroupFieldsList
    },
    groupAssignRulesList() {
      return appGroupBulkEdit.getters.getGroupAssignRulesList
    },
    groupAccessAndBehaviorSettings() {
      return appGroupBulkEdit.getters.getGroupAccessAndBehaviorSettings
    },
    haveDifferentGroupTypes() {
      return appGroupBulkEdit.getters.getHaveDifferentGroupTypes
    },
  },
  methods: {
    updateVisible(val: boolean) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    async inputName(val: string) {
      const value = !!val
      const property = 'groupNameForAll'
      await appGroupBulkEdit.actions.updateGeneralSettingsProperty({ property, value })
    },
    async inputStatus(val: string|null) {
      const value = !!val
      const property = 'groupStatusForAll'
      await appGroupBulkEdit.actions.updateGeneralSettingsProperty({ property, value })
    },
    async inputSessions(val: []) {
      const value = !!val.length
      const property = 'sessionsForAll'
      await appGroupBulkEdit.actions.updateGeneralSettingsProperty({ property, value })
    },
    async inputSessionsCustomPricing(value: boolean) {
      const property = 'sessionsCustomPricingForAll'
      await appGroupBulkEdit.actions.updateGeneralSettingsProperty({ property, value })
    },
  },
})
