import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBulkTagAssign() {
  // Use toast
  const toast = useToast()

  const assignTagsToApplications = queryParams => axios
    .post('/auth/applications-tags-assign', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tags were successfully assigned',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error assigning Tags to Applications',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    assignTagsToApplications,
  }
}
