import axios from '@axios'

export const fetchTagSets = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/tag-sets', { params })
  return res
}

export const fetchTagSetsOptionsList = async (params?: any): Promise<any> => {
  const response = await axios.get('/auth/tag-sets', { params })
  return response.data.data.reduce((array: any, tagSet: any) => {
    const children = tagSet.tags.reduce((arr: any, tag: any) => {
      arr.push({
        id: tag.id,
        label: tag.name,
        name: tag.name,
        selectType: tagSet.select_type,
        tagSetId: tagSet.id,
        color: tag.color,
      })
      return arr
    }, [])
    array.push({
      id: tagSet.id + tagSet.name,
      tagSetId: tagSet.id,
      label: tagSet.name,
      children,
    })
    return array
  }, [])
}

export const createTagSet = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/tag-sets', params)
  return res
}

export const _ = null
