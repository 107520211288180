<template>
  <b-modal
    id="edit-application-task"
    ref="edit-application-task"
    centered
    size="lg"
    title="Edit Task"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setTaskData"
  >
    <Spinner
      v-if="isLoading"
    />
    <template v-else>
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Task Name"
          >
            <b-form-input
              v-model="taskDetail.name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Student Name"
          >
            <b-form-input
              v-model="userDetail.full_name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
          class="mb-1"
        >
          <b-form-group
            label="Status"
            label-for="status"
          >
            <v-select
              v-model="applicationTaskStatus"
              :options="statusOptions"
              :clearable="false"
              placeholder="Select Status"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <div
        v-if="applicationTaskStatus !== 'Manual Deleted'"
      >
        <FormResultEditRow
          v-for="(item, index) in fieldsList"
          :key="item.frontID"
          :fields-data="item"
          :field-index="index"
          :files-for-remove="filesForRemove"
          :user-name="userDetail.full_name"
        />
      </div>

    </template>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        @click="$refs['edit-application-task'].hide()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        @click="saveForm"
      >
        Save
      </b-button>
    </template>
  </b-modal>

</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/tasks/taskStoreModule'
import FormResultEditRow from '@/views/admin/forms/form-result-edit/FormResultEditRow.vue'
import useApplicationTaskList from '@/views/admin/tasks/application-task-list/useApplicationTaskList'

import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import { removeFile, uploadFile } from '@/api/files'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BFormInput,
    BFormGroup,
    FormResultEditRow,
    vSelect,
    Spinner,
  },
  mixins: [formBuilderPreparations],
  props: {
    applicationTaskId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      applicationTaskStatus: null,
      defaultFieldsList: [],
      fieldsList: [],
      fieldsValue: [],
      filesForRemove: [],
      groupsList: [],
      taskDetail: {},
      userDetail: {},
      statusOptions: ['Completed', 'To Do', 'Manual Deleted'],
      isLoading: false,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-application-tasks'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchApplicationTask,
      updateApplicationTask,
    } = useApplicationTaskList(root)

    return {
      fetchApplicationTask,
      updateApplicationTask,
    }
  },
  methods: {
    fileUpload(file) {
      const formData = new FormData()
      formData.append('file', file)
      return uploadFile(`tasks-files-${new Date().getTime()}`, formData)
    },
    async prepareApplicationTaskData(ID) {
      await this.fetchApplicationTask(ID).then(response => {
        this.taskDetail = response.task
        this.fieldsValue = response.task_results
        this.userDetail = response.user
        this.applicationTaskStatus = response.status

        if (response.form) {
          this.defaultFieldsList = response.form.fields
            .map(row => {
              if (row.type === 'file_upload') {
                return {
                  id: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id,
                  field_id: row.id,
                  value: null,
                  files: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.files || [],
                  label: row.label,
                  name: row.name,
                  options: this.designateOptions(row.type, row.options),
                  type: this.transformField(row.type),
                  required: row.required,
                  disabled: true,
                }
              }
              return {
                id: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id,
                field_id: row.id,
                value: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.result,
                label: row.label,
                name: row.name,
                options: this.designateOptions(row.type, row.options),
                type: this.transformField(row.type),
                required: row.required,
                disabled: true,
              }
            })
        } else {
          this.defaultFieldsList = this.fieldsValue
            .map(row => {
              const preparedData = {
                id: row.id,
                field_id: row.field_id,
                label: 'deleted field',
                name: 'deleted field',
                required: row.required,
                disabled: true,
                readonly: true,
              }

              if (row.files.length > 0) {
                preparedData.value = ''
                preparedData.files = row.files
                preparedData.type = 'file_upload'
              } else {
                preparedData.value = row.result
                preparedData.files = []
                preparedData.type = 'short_text'
              }

              return preparedData
            })
        }

        this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))
      })
    },
    async setTaskData() {
      if (this.applicationTaskId) {
        this.isLoading = true
        await this.prepareApplicationTaskData(this.applicationTaskId).then(() => {
          this.isLoading = false
        })
      }
    },
    async saveForm() {
      if (this.applicationTaskStatus === 'Manual Deleted') {
        const deletableTaks = {
          id: this.applicationTaskId,
          user_name: this.userDetail.full_name,
        }
        store.commit('app-application-tasks/SET_DELETABLE_TASK', deletableTaks)
        this.$nextTick(() => {
          store.commit('app-application-tasks/SET_CONFIRMATION_MODAL_SHOW', true)
        })
        return
      }
      // Remove old files
      if (this.filesForRemove.length > 0) {
        this.filesForRemove.flat(Infinity).forEach(file => {
          removeFile({ file_path: file.path })
        })
      }

      const queryParams = {
        form_id: this.taskDetail.form.id,
        application_task_id: this.applicationTaskId,
        status: this.applicationTaskStatus,
        fields: [],
        form_data: {},
      }

      let fileFieldsCount = 0
      const filesLinks = []
      const filesNames = []

      const fileFields = this.fieldsList.filter(row => row.type === 'file_upload' && row.value)

      if (fileFields.length > 0) {
        fileFields.forEach(file => {
          Array.from(file.value).forEach(val => {
            filesLinks.push(this.fileUpload(val))
          })
        })
      }

      Promise.all(filesLinks)
        .then(responses => responses.forEach(
          response => {
            filesNames.push(response.file_path)
          },
        ))
        .finally(() => {
          this.fieldsList.forEach(row => {
            if (row.type === 'file_upload' && row.value) {
              queryParams.fields.push({
                id: row.id,
                field_id: row.field_id,
                files: filesNames.slice(fileFieldsCount, row.value.length),
              })
              queryParams.form_data[row.name] = filesNames.slice(fileFieldsCount, row.value.length)
              fileFieldsCount += 1
            } else {
              queryParams.fields.push({
                id: row.id,
                field_id: row.field_id,
                files: row.files?.map(item => item.path) || [],
                result: row.value,
              })
              queryParams.form_data[row.name] = row.value
            }
          })

          this.updateApplicationTask(queryParams)
            .finally(() => {
              this.$refs['edit-application-task'].hide()
              this.$emit('updated')
            })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
