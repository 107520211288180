import axios from '@axios'

export const fetchApplicationsListForGroupAutoAssign = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/applications-for-group-auto-assign', { params })
  return res
}

export const fetchApplicationGroupOptions = async (id: string, params?: any): Promise<any> => {
  const res = await axios.post(`auth/applications/${id}/groups`, params)
  return res
}

export const fetchSessionForSelectByApplication = async (id: string, params?: any): Promise<any> => {
  const res = await axios.post(`auth/applications/${id}/sessions`, params)
  return res
}

export const _ = null
