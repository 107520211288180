<template>
  <b-dropdown
    id="dropdown-form"
    ref="dropdown"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    right
    variant="flat-primary"
    no-caret
    no-flip
    lazy
    @click.native.stop
    @hide="onDropdownHideEvent"
    @show="setOptions"
  >
    <template #button-content>
      <feather-icon
        icon="EyeIcon"
      />
      <span class="pl-1">Board Option</span>
    </template>
    <b-dropdown-form
      class="options-dropdown-form"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-25 pr-4 font-weight-bolder">
          Board Option
        </h5>
        <div>
          <b-button
            variant="flat-primary"
            size="sm"
            @click="closeDropdown"
          >
            Cancel
          </b-button>
          <b-button
            variant="flat-primary"
            size="sm"
            @click="saveChosenOptions"
          >
            Save
          </b-button>
        </div>
      </div>
      <hr>
      <b-form-group
        :label="'Choose list or kanban view'"
        label-class="font-weight-bolder pb-1"
      >
        <div
          class="d-flex"
        >
          <b-form-radio
            v-model="selectedViewType"
            name="some-radios"
            value="Kanban"
          >
            Kanban
          </b-form-radio>
          <b-form-radio
            v-model="selectedViewType"
            name="some-radios"
            value="List"
            class="ml-2"
          >
            List view
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group
        :label="'Selecting Fields'"
        label-class="font-weight-bolder py-1"
      >
        <draggable
          v-model="selectedColumns"
          class="d-flex flex-wrap mb-1 align-items-center"
        >
          <template
            v-for="column in selectedColumns"
          >
            <div
              v-if="!['selected', 'actions'].includes(column.name)"
              :key="column.label"
              class="d-flex align-items-center column-item mb-1 mr-1"
            >
              <b-form-checkbox
                :id="column.key"
                v-model="column.selected"
              />
              <label
                :for="column.key"
                class="ml-auto mb-0 cursor-pointer label-column-item"
              >{{ column.label }}
              </label>
            </div>
          </template>
        </draggable>
      </b-form-group>

      <b-row>
        <b-col
          cols="8"
        >
          <b-form-group
            label="Sort By Field"
            label-class="font-weight-bolder py-1"
          >
            <v-select
              v-model="sortByFieldDefault"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="availableFieldsForSorting"
              label="label"
              :reduce="option => option.key"
              :clearable="false"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          class="pl-2"
        >
          <b-form-group
            label="Sort by Desc"
            label-cols="12"
            label-class="font-weight-bolder py-1"
          >
            <b-form-checkbox
              v-model="isSortByDescDefault"
              class="mr-0 mt-50"
              name="is-rtl"
              switch
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        label="Select Tag Type"
        label-class="font-weight-bolder py-1"
      >
        <v-select
          v-model="selectedTagSets"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="tagSetsList"
          :clearable="false"
          label="name"
          multiple
          :placeholder="'Select Tag Type'"
        />
      </b-form-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownForm,
  BFormCheckbox,
  BFormGroup,
  BButton,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BFormRadio,
    vSelect,
    draggable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedColumns: [],
      selectedViewType: 'List',
      availableFieldsForSorting: [],
      sortByFieldDefault: 'created_at',
      isSortByDescDefault: false,
      tagSetsList: [],
      selectedTagSets: [],
      canHide: false,
    }
  },
  computed: {
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    boardViewType: {
      get() {
        return store.getters['app-applications/getBoardViewType']
      },
      set(val) {
        store.commit('app-applications/SET_BOARD_VIEW_TYPE', val)
      },
    },
    storageTableColumns: {
      get() {
        return store.getters['app-applications/getStorageTableColumns']
      },
      set(val) {
        store.commit('app-applications/SET_SELECTED_VIEW_COLUMNS', val)
      },
    },
    selectedViewTags: {
      get() {
        return store.getters['app-applications/getSelectedViewTags']
      },
      set(val) {
        store.commit('app-applications/SET_SELECTED_VIEW_TAGS', val)
      },
    },
    storageSortBy: {
      get() {
        return store.getters['app-applications/getStorageSortBy']
      },
      set(val) {
        store.commit('app-applications/SET_STORAGE_SORT_BY', val)
      },
    },
    storageIsSortByDesc: {
      get() {
        return store.getters['app-applications/getStorageIsSortByDesc']
      },
      set(val) {
        store.commit('app-applications/SET_STORAGE_IS_SORT_BY_DESC', val)
      },
    },
  },
  created() {
    this.fetchingTagSets()
  },
  methods: {
    closeDropdown() {
      this.canHide = true
      this.$refs.dropdown.hide()
    },
    onDropdownHideEvent(bvEvent) {
      if (this.canHide) {
        this.canHide = false
      } else {
        bvEvent.preventDefault()
      }
    },
    async fetchingTagSets() {
      const queryParams = {
        programId: this.programId,
        hasTags: true,
        isHidden: 0,
      }
      this.tagSetsList = await this.$store.dispatch('tags/fetchTagSetsList', queryParams)
    },
    setOptions() {
      this.selectedViewType = this.boardViewType
      this.selectedTagSets = this.selectedViewTags.map(tag => ({ ...tag }))
      this.selectedColumns = this.storageTableColumns.reduce((arr, item) => {
        arr.push(item)
        return arr
      }, [])
      this.availableFieldsForSorting = this.storageTableColumns.reduce((arr, item) => {
        if (item.sortable) {
          arr.push(item)
        }
        return arr
      }, [])
      this.isSortByDescDefault = this.storageIsSortByDesc
      this.sortByFieldDefault = this.storageSortBy
    },
    saveChosenOptions() {
      this.boardViewType = this.selectedViewType
      this.storageTableColumns = this.selectedColumns
      this.selectedViewTags = this.selectedTagSets
      this.storageIsSortByDesc = this.isSortByDescDefault
      this.storageSortBy = this.sortByFieldDefault
      this.closeDropdown()
    },
    cancel() {
      this.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>

.options-dropdown-form {
  width: 30vw;
}
.column-item {
  background: #F6F6F6;
  border-radius: 6px;
  padding: 0.786rem 1.5rem;
}
.label-column-item {
  font-size: 0.9rem;
}

</style>
