<template>
  <validation-observer
    ref="groupCustomFieldsRUles"
    tag="form"
  >
    <b-list-group-item
      class="field-area"
    >
      <b-row>
        <!-- Field Label -->
        <b-col
          md="5"
        >
          <b-form-group
            label="Field Name"
            label-for="group_name"
          >
            <validation-provider
              #default="{ errors }"
              name="field_label"
              rules="required"
            >
              <b-form-input
                v-model="fieldData.label"
                type="text"
                :class="{'is-invalid': errors.length > 0 }"
                @change="handleLabelChange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Field Value -->
        <b-col
          md="5"
        >
          <b-form-group
            label="Field Value"
            label-for="field_value"
          >
            <validation-provider
              #default="{ errors }"
              name="field_value"
              rules="required"
            >
              <b-form-input
                v-model="fieldData.value"
                type="text"
                :class="{'is-invalid': errors.length > 0 }"
                @change="$emit('change')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Remove Button -->
        <b-col
          sm="2"
          class="align-self-center"
        >
          <div
            style="padding-top: 7px"
          >
            <SquareIcon
              size="21"
              icon="Trash2Icon"
              class="cursor-pointer"
              @onIconClick="removeCustomFieldItem"
            />
          </div>
        </b-col>
      </b-row>
    </b-list-group-item>
  </validation-observer>
</template>

<script>
import {
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  components: {
    BListGroupItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    ValidationObserver,
    ValidationProvider,

    SquareIcon,
  },
  props: {
    fieldData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeCustomFieldItem() {
      this.$emit('removeFieldsRow', this.fieldData.id)
      this.$emit('change')
    },
    handleLabelChange(event) {
      this.fieldData.label = event.toLowerCase()
      this.$emit('change')
    },
  },
}
</script>

<style scoped>
  .field-area {
    background: #FBFBFC;
  }
</style>
