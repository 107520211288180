import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { ParentsListState, IParentsListRequest } from './types'
import { fetchUsersList } from '@/api/users'
import {
  deleteParent,
  exportParents,
  fetchParentStatuses,
  updateParent,
} from '@/api/parents'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import { updateMember } from '@/api/familyMember'
import downloadFile from '@/helpers/downloadExportFile'

// eslint-disable-next-line import/prefer-default-export
export const appParentsList = createModule(
  'appParentsList',
  {
    namespaced: true,
    state: {
      tableColumns: [],
      baseTableColumns: [
        { key: 'name', label: 'name', sortable: false },
        { key: 'id', label: 'user id', sortable: false },
        { key: 'is_primary', label: 'primary', sortable: false },
        { key: 'relationship', label: 'relationship', sortable: false },
        { key: 'email', label: 'email', sortable: false },
        { key: 'phone', label: 'phone', sortable: false },
        { key: 'actions' },
      ],
      perPage: Number(localStorage.getItem('allParentsPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: localStorage.getItem('allParentsSortBy') ? JSON.parse(localStorage.getItem('allParentsSortBy')!) : 'is_primary',
      isSortDirDesc: localStorage.getItem('allParentsIsSortDesc') ? (JSON.parse(localStorage.getItem('allParentsIsSortDesc')!) === true) : true,
      isLoading: false,
      items: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        current_page: null,
        last_page: null,
      },
      filterDataFamilyId: null,
      filterDataStatus: '',
      filterStatusOptions: [],
    } as ParentsListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getVisibleTableColumns: state => state.tableColumns.filter(item => item.selected),
      getBaseTableColumns: state => state.baseTableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getIsLoading: state => state.isLoading,
      getDataMeta: state => state.dataMeta,
      getFilterDataStatus: state => state.filterDataStatus,
      getFilterDataFamilyId: state => state.filterDataFamilyId,
      getFilterStatusOptions: state => state.filterStatusOptions,
      getHasNextPage: state => state.dataMeta.current_page !== state.dataMeta.last_page,
      getPrevPage: state => state.dataMeta.current_page !== null && state.dataMeta.current_page > 1,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('allParentsPerPage', val.toString())
        state.perPage = val
      },
      SET_TABLE_COLUMNS: (state, val) => {
        state.tableColumns = val
        localStorage.setItem('allParentsTableColumns', JSON.stringify(val))
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
        localStorage.setItem('allParentsSortBy', JSON.stringify(val))
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
        localStorage.setItem('allParentsIsSortDesc', JSON.stringify(val))
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_FILTER_DATA_STATUS(state, val: any) {
        state.filterDataStatus = val
      },
      SET_FILTER_DATA_FAMILY_ID(state, val: any) {
        state.filterDataFamilyId = val
      },
      SET_FILTER_STATUS_OPTIONS(state, val: any) {
        state.filterStatusOptions = val
      },
      SET_ITEMS(state, val) {
        state.items = val
      },
      CLEAR_FILTERS(state) {
        state.filterDataStatus = ''
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('allParentsPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
        state.filterDataStatus = query.filterDataStatus || ''
      },
    },
    actions: {
      async fetchParentsList({ state }, params = {}) {
        try {
          let sortBy = String(state.sortBy)
          if (sortBy === 'registration_date') {
            sortBy = 'created_at'
          }
          const queryParams: IParentsListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
            IsParentMemberRole: true,
          }
          // if (state.searchQuery) queryParams.family_name = state.searchQuery
          if (state.searchQuery) queryParams.search = state.searchQuery
          if (state.filterDataFamilyId) queryParams.familyId = state.filterDataFamilyId
          if (state.filterDataStatus) queryParams.status = state.filterDataStatus
          if (params.familyId) queryParams.familyId = params.familyId

          const response: any = await fetchUsersList(queryParams)

          const {
            last_page, current_page, total, from, to,
          } = response.data.meta

          const dataMeta = {
            current_page,
            last_page,
            from,
            to,
            of: total,
          }

          appParentsList.mutations.SET_TOTAL_ITEMS(total)
          appParentsList.mutations.SET_DATA_META(dataMeta)
          appParentsList.mutations.SET_ITEMS(response.data.data)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching parents list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async deleteParent(ctx, id) {
        try {
          await deleteParent(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success deleting Parent',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting Parent',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async updFamilyMember({ state }, data) {
        try {
          await updateMember(data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async exportParentCSV({ state }) {
        try {
          appParentsList.mutations.SET_IS_LOADING(true)

          let sortBy = String(state.sortBy)
          if (sortBy === 'registration_date') {
            sortBy = 'created_at'
          }
          const queryParams: IParentsListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
            IsParentMemberRole: true,
          }

          if (state.searchQuery) queryParams.search = state.searchQuery
          if (state.filterDataFamilyId) queryParams.familyId = state.filterDataFamilyId
          if (state.filterDataStatus) queryParams.status = state.filterDataStatus

          const response = await exportParents(queryParams)
          if (response.status === 200) {
            downloadFile(response.data, 'Parents', 'csv')
          }
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting parents',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appParentsList.mutations.SET_IS_LOADING(false)
        }
      },
      async fetchParentStatuses() {
        try {
          const response: any = await fetchParentStatuses()

          appParentsList.mutations.SET_FILTER_STATUS_OPTIONS(response.data.data)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching parent statuses',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async approveAccount({ state }, ID: string) {
        try {
          const data = {
            id: ID,
            is_approved: true,
          }
          await updateParent(data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appParentsList.register(store)
