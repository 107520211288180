<template>
  <b-modal
    id="add-tag-triggers"
    ref="add-tag-triggers"
    centered
    size="xl"
    title="Add Tag Triggers"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    :ok-disabled="!canUpdateTagTriggers"
    @show="loadData"
    @hidden="$emit('reset')"
    @ok="postTagTriggers"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="tagTriggers"
        tag="form"
      >
        <b-row>
          <template
            v-for="(tagTriggerRow, index) in filteredTagTriggers"
          >
            <!--  Event  -->
            <b-col
              :key="`event-${index}`"
              sm="2"
              class="mb-1"
            >
              <b-form-group
                label="Event"
              >
                <validation-provider
                  #default="{ errors }"
                  name="event"
                  rules="required"
                >
                  <v-select
                    v-model="tagTriggerRow.event"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="triggerEventsOptions"
                    :placeholder="'Select Event'"
                    :clearable="false"
                    :reduce="val => val.value"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Tag  -->
            <b-col
              :key="`tag-${index}`"
              sm="2"
              class="mb-1"
            >
              <b-form-group
                label="Tag"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tag"
                  rules="required"
                >
                  <treeselect
                    v-model="tagTriggerRow.tag_id"
                    :multiple="false"
                    :options="tagSetOptionsList"
                    search-nested
                    :default-expand-level="1"
                    :disable-branch-nodes="true"
                    placeholder="Select Tag"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Trigger  -->
            <b-col
              :key="`trigger-${index}`"
              sm="2"
              class="mb-1"
            >
              <b-form-group
                label="Trigger"
              >
                <validation-provider
                  #default="{ errors }"
                  name="trigger"
                  rules="required"
                >
                  <v-select
                    v-model="tagTriggerRow.trigger"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="triggerTypesOptions"
                    :placeholder="'Select Trigger'"
                    :clearable="false"
                    :reduce="val => val.value"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Email Template  -->
            <b-col
              v-if="tagTriggerRow.trigger === 'email' || tagTriggerRow.trigger == null"
              :key="`email-${index}`"
              sm="2"
              class="mb-1"
            >
              <b-form-group
                label="Email Template"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email template"
                  rules="required"
                >
                  <v-select
                    v-model="tagTriggerRow.template_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="emailTemplatesOptions"
                    :clearable="false"
                    :placeholder="'Select Email Template'"
                    value="id"
                    label="name"
                    :reduce="val => val.id"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Sms Template  -->
            <b-col
              v-if="tagTriggerRow.trigger === 'sms'"
              :key="`sms-${index}`"
              sm="2"
              class="mb-1"
            >
              <b-form-group
                label="Sms Template"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sms template"
                  rules="required"
                >
                  <v-select
                    v-model="tagTriggerRow.template_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="smsTemplatesOptions"
                    :clearable="false"
                    :placeholder="'Select Sms Template'"
                    value="id"
                    label="name"
                    :reduce="val => val.id"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--  Recipient  -->
            <b-col
              v-if="tagTriggerRow"
              :key="`recipient-${index}`"
              sm="3"
              class="mb-1"
            >
              <b-form-group
                label="Recipient"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient"
                  rules="required"
                >
                  <v-select
                    v-model="tagTriggerRow.recipient_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="recipientsOptions"
                    :clearable="false"
                    :placeholder="'Select Recipients'"
                    :reduce="val => val.value"
                    :class="{'is-invalid': errors.length > 0 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Input Delete -->
            <b-col
              :key="`delete-${index}`"
              sm="1"
              class="align-self-center"
            >
              <SquareIcon
                v-if="canDeleteTagTriggers"
                size="21"
                icon="Trash2Icon"
                class="cursor-pointer"
                @onIconClick="removeRow(tagTriggerRow.frontID)"
              />
            </b-col>
          </template>

          <b-col
            v-if="programId"
            sm="3"
          >
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center justify-content-center w-100 mt-2"
              :disabled="!canCreateTagTriggers"
              @click="addNewTagTriggerRow"
            >
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-1"
              />
              Add Trigger
            </b-button>
          </b-col>
          <span v-else>
            Program should be created first
          </span>
        </b-row>
      </validation-observer>
      <div
        v-if="validationErrors.length"
        class="form-group"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center text-danger"
        >
          {{ validationError }}
        </p>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import useTriggersSetting from '@/views/admin/program/program-communication-list/useTriggersSetting'
import { fetchTagSetsOptionsList } from '@/api/tags'
import { fetchSmsTemplatesList } from '@/api/smsTemplates'

export default {
  name: 'TagTriggersSettingsModal',
  components: {
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BOverlay,

    ValidationObserver,
    ValidationProvider,
    vSelect,
    SquareIcon,

    Treeselect,
  },
  props: {
    programId: {
      type: [String, null],
      required: true,
    },
  },
  data() {
    return {
      rowsCount: 0,
      tagTriggers: [],
      emailTemplatesOptions: [],
      smsTemplatesOptions: [],
      tagSetOptionsList: [],
      triggerTypesOptions: [],
      triggerEventsOptions: [],
      ppaFields: [],
      recipientsOptions: [
        {
          value: 'admin',
          label: 'Admin',
        },
        {
          value: 'parent',
          label: 'Parent',
        },
      ],
      validationErrors: [],
      isLoading: false,
    }
  },
  computed: {
    filteredTagTriggers() {
      return this.tagTriggers.filter(tag => !tag.delete)
    },
  },
  setup() {
    const {
      fetchTagTriggers,
      fetchEmailTemplates,
      saveTagTriggers,
      fetchProgramTags,
      fetchTagTriggerEvents,
      fetchTagTriggerTypes,
      fetchPpaUniqueFields,
      canCreateTagTriggers,
      canUpdateTagTriggers,
      canDeleteTagTriggers,
    } = useTriggersSetting()

    return {
      fetchTagTriggers,
      fetchEmailTemplates,
      saveTagTriggers,
      fetchProgramTags,
      fetchTagTriggerEvents,
      fetchTagTriggerTypes,
      fetchPpaUniqueFields,
      canCreateTagTriggers,
      canUpdateTagTriggers,
      canDeleteTagTriggers,
    }
  },
  methods: {
    async loadData() {
      this.isLoading = true
      await this.setTagTriggersData()
      await this.fetchEmailTemplates().then(response => {
        this.emailTemplatesOptions = response
      })
      await fetchSmsTemplatesList().then(response => {
        this.smsTemplatesOptions = response.data.data
      })
      await fetchTagSetsOptionsList({ programId: this.programId }).then(response => {
        this.tagSetOptionsList = response
      })
      await this.fetchTagTriggerTypes().then(response => {
        this.triggerTypesOptions = response
      })
      await this.fetchTagTriggerEvents().then(response => {
        this.triggerEventsOptions = response
      })
      await this.fetchPpaUniqueFields({ programId: this.programId }).then(response => {
        this.ppaFields = response.reduce((arr, item) => {
          arr.push({
            value: `ppa:${item}`,
            label: `PPA: ${item}`,
          })
          return arr
        }, this.recipientsOptions)
      })
      this.isLoading = false
    },
    async setTagTriggersData() {
      await this.fetchTagTriggers({ programId: this.programId })
        .then(response => {
          this.tagTriggers = response.map((item, index) => (
            {
              frontID: index,
              delete: false,
              ...item,
            }
          ))

          this.rowsCount = this.tagTriggers.length
        })
    },
    isFrontIDExists(key) {
      return this.tagTriggers.some(field => field.frontID === key)
    },
    addNewTagTriggerRow() {
      do {
        this.rowsCount += 1
      } while (this.isFrontIDExists(this.rowsCount))

      this.tagTriggers.push({
        id: null,
        program_id: this.programId,
        tag_id: null,
        event: null,
        trigger: null,
        template_id: null,
        recipient_type: null,
        delete: false,
        frontID: this.rowsCount,
        newField: true,
      })
    },
    removeRow(ID) {
      const curItem = this.tagTriggers.findIndex(item => item.frontID === ID)
      this.tagTriggers[curItem].delete = true

      if (this.tagTriggers[curItem].newField) {
        this.tagTriggers.splice(curItem, 1)
      }
    },
    async postTagTriggers(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(reject => {
        this.$refs.tagTriggers.validate().then(success => {
          if (success) {
            this.saveTagTriggers(this.tagTriggers)
              .then(response => {
                if (response.data && response.status === 422) {
                  const validationErrors = Object.values(response.data.errors)
                  this.validationErrors = validationErrors.flat()
                } else {
                  this.validationErrors = []
                  this.$nextTick(() => {
                    this.$refs['add-tag-triggers'].toggle('#toggle-btn')
                    this.$emit('refresh')
                  })
                }
              })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
.vue-treeselect__label {
  font-size: 16px !important;
  height: 32px;
}
</style>
