import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import { ref } from '@vue/composition-api'
import moment from 'moment/moment'
import router from '@/router'
import store from '@/store'
import {
  GroupsListState,
  IGroupItemState,
  IGroupsListRequest,
  ISessionItemState,
} from './types'
import {
  deleteGroup,
  downloadBadges,
  fetchGroups,
  fetchGroupsForExport,
  fetchGroupsUsers,
  fetchGroupsUsersExtraDetails,
} from '@/api/groups'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import { fetchSessionsList } from '@/api/sessions'
import { fetchPpaList } from '@/api/ppa'
import { fetchGroupTypesForSelect } from '@/api/groupTypes'
import { fetchTagSetsOptionsList } from '@/api/tags'
import { fetchApplicationsListForGroupAutoAssign } from '@/api/applications'
import { appGroupBulkEdit } from '@/store/modules/app-groups/app-group-bulk-edit'
import downloadFile from '@/helpers/downloadExportFile'

// eslint-disable-next-line import/prefer-default-export
export const appGroupsList = createModule(
  'appGroupsList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'show_details', label: '#' },
        { key: 'name', label: 'Group', sortable: true },
        { key: 'member_count', label: 'Members', sortable: true },
        { key: 'type_name', label: 'type', sortable: true },
        { key: 'parent_group_name', label: 'parent group', sortable: true },
        { key: 'status', sortable: true },
        { key: 'has_tuition_fee', label: 'Tuition/Fee', sortable: true },
        { key: 'actions' },
      ],
      perPage: Number(localStorage.getItem('groupsPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      tableItems: ref([]),
      isLoading: false,
      expandAll: false,
      filterDataStatus: '',
      filterDataType: '',
      filterDataSession: '',
      filterDataTuition: '',
      filterStatusOptions: ['Active', 'Inactive'],
      filterTuitionFeeOptions: ['Tuition', 'Fee'],
      filterSessionOptions: [],
      ppaList: [],
      groupTypeOptions: [],
      tagSetsOptionsList: [],
    } as GroupsListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getIsLoading: state => state.isLoading,
      getExpandAll: state => state.expandAll,
      getTableItems: state => state.tableItems,
      getFilterDataStatus: state => state.filterDataStatus,
      getFilterDataType: state => state.filterDataType,
      getFilterDataSession: state => state.filterDataSession,
      getFilterDataTuition: state => state.filterDataTuition,
      getFilterStatusOptions: state => state.filterStatusOptions,
      getFilterTuitionFeeOptions: state => state.filterTuitionFeeOptions,
      getFilterSessionOptions: state => state.filterSessionOptions,
      getPpaList: state => state.ppaList,
      getGroupTypeOptions: state => state.groupTypeOptions,
      getTagSetsOptionsList: state => state.tagSetsOptionsList,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('groupsPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_TABLE_ITEMS(state, val: any) {
        state.tableItems = val
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('groupsPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_EXPAND_ALL(state, val: boolean) {
        state.expandAll = val
      },
      SET_FILTER_DATA_STATUS(state, val: string) {
        state.filterDataStatus = val
      },
      SET_FILTER_DATA_TYPE(state, val: string) {
        state.filterDataType = val
      },
      SET_FILTER_DATA_SESSION(state, val: string) {
        state.filterDataSession = val
      },
      SET_FILTER_DATA_TUITION(state, val: string) {
        state.filterDataTuition = val
      },
      SET_FILTER_SESSION_OPTIONS(state, val: string | string[]) {
        state.filterSessionOptions = val
      },
      SET_PPA_LIST(state, val: []) {
        state.ppaList = val
      },
      SET_GROUP_TYPE_OPTIONS(state, val: []) {
        state.groupTypeOptions = val
      },
      SET_TAG_SET_OPTIONS_LIST(state, val: []) {
        state.tagSetsOptionsList = val
      },
      CLEAR_FILTERS(state) {
        state.filterDataType = ''
        state.filterDataStatus = ''
        state.filterDataSession = ''
        state.filterDataTuition = ''
      },
    },
    actions: {
      async prepareQueryParams({ state, rootGetters }) {
        const queryParams: IGroupsListRequest = {
          exceptProgramType: 'Program',
          perPage: state.perPage,
          page: state.currentPage || 1,
          sortBy: state.sortBy,
          sortDesc: state.isSortDirDesc,
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        }
        if (state.searchQuery) {
          queryParams.name = state.searchQuery
        }
        if (state.filterDataStatus) {
          queryParams.status = state.filterDataStatus
        }
        if (state.filterDataType && state.filterDataType !== 'All') {
          queryParams.typeId = state.filterDataType
        }
        if (state.filterDataSession) {
          queryParams.sessionId = state.filterDataSession
        }
        if (state.filterDataTuition) {
          queryParams.hasTuition = state.filterDataTuition === 'Tuition' ? 1 : 0
        }

        return queryParams
      },
      async fetchGroupsList() {
        try {
          appGroupsList.mutations.SET_IS_LOADING(true)
          appGroupsList.mutations.SET_EXPAND_ALL(false)

          const queryParams = await appGroupsList.actions.prepareQueryParams()

          const response:any = await fetchGroups(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appGroupsList.mutations.SET_TOTAL_ITEMS(total)
          appGroupsList.mutations.SET_DATA_META(dataMeta)
          appGroupsList.mutations.SET_TABLE_ITEMS(response.data.data)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching groups' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        } finally {
          appGroupsList.mutations.SET_IS_LOADING(false)
        }
      },
      async deleteGroup(ctx, id) {
        try {
          appGroupsList.mutations.SET_IS_LOADING(true)
          await deleteGroup(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success deleting group',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting group',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appGroupsList.mutations.SET_IS_LOADING(false)
        }
      },
      async fetchSessions({ rootGetters }) {
        try {
          const response = await fetchSessionsList({
            programId: rootGetters['verticalMenu/getDefaultProgram'],
          })
          const data = response.data.data.reduce((arr: ISessionItemState[], item: ISessionItemState) => {
            arr.push({ id: item.id, name: item.name })
            return arr
          }, [])

          appGroupsList.mutations.SET_FILTER_SESSION_OPTIONS(data)
          appGroupBulkEdit.mutations.SET_SESSIONS_LIST(data)
          return data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching sessions list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error.response
        }
      },
      async fetchPpaList({ rootGetters }) {
        try {
          const response = await fetchPpaList({
            programId: rootGetters['verticalMenu/getDefaultProgram'],
          })

          appGroupsList.mutations.SET_PPA_LIST(response.data.data)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching ppa list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error.response
        }
      },
      async fetchGroupTypesList({ rootGetters }) {
        try {
          const response = await fetchGroupTypesForSelect({
            programId: rootGetters['verticalMenu/getDefaultProgram'],
            exceptProgramType: 'Program',
          })
          appGroupsList.mutations.SET_GROUP_TYPE_OPTIONS(response.data.data)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching groups types' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error.response
        }
      },
      async fetchTagSetsListOptions({ rootGetters }) {
        try {
          const response = await fetchTagSetsOptionsList({
            programId: rootGetters['verticalMenu/getDefaultProgram'],
          })

          appGroupsList.mutations.SET_TAG_SET_OPTIONS_LIST(response)

          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Tags list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        }
      },
      async fetchApplicationsForGroupAutoAssign({ state, rootGetters }, groupId: string) {
        try {
          return await fetchApplicationsListForGroupAutoAssign({
            program_id: rootGetters['verticalMenu/getDefaultProgram'],
            group_i: groupId,
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Applications list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        }
      },
      async fetchGroupsUsersList({ state }, queryParams: any) {
        try {
          return await fetchGroupsUsers(queryParams)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Groups Users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        }
      },
      async exportGroupsCSV() {
        try {
          appGroupsList.mutations.SET_IS_LOADING(true)

          const queryParams = await appGroupsList.actions.prepareQueryParams()
          queryParams.exceptProgramType = 'Program'

          const response = await fetchGroupsForExport(queryParams)
          if (response.status === 200) {
            let filename = `groups-export-${moment().format('YYYY-MM-DD')}`
            filename = filename.replace(/\s/g, '-')

            downloadFile(response.data, filename, 'csv')
          }
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting groups',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appGroupsList.mutations.SET_IS_LOADING(false)
        }
      },
      async exportGroupsBadgesZip({ state }, group: IGroupItemState) {
        try {
          appGroupsList.mutations.SET_IS_LOADING(true)
          const queryParams = {
            groups: [{ group_id: group.id }],
          }

          const response = await downloadBadges(queryParams)
          if (response.status === 200) {
            const filename = `${group.name}(badges)-${moment().format('YYYY-MM-DD')}`
            downloadFile(response.data, filename, 'zip')
          }
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error downloading badges',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appGroupsList.mutations.SET_IS_LOADING(false)
        }
      },
      async fetchGroupUsersExtraDetailsList({ state }, queryParams) {
        try {
          return await fetchGroupsUsersExtraDetails(queryParams)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching group users',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          return error.response
        }
      },
    },
  },
)

appGroupsList.register(store)
