import { render, staticRenderFns } from "./FileFolderDetails.vue?vue&type=template&id=3a77e482&scoped=true&"
import script from "./FileFolderDetails.vue?vue&type=script&lang=js&"
export * from "./FileFolderDetails.vue?vue&type=script&lang=js&"
import style0 from "./FileFolderDetails.vue?vue&type=style&index=0&id=3a77e482&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a77e482",
  null
  
)

export default component.exports