<template>
  <b-col
    md="6"
    xl="6"
  >
    <b-form-group
      :label="fieldsData.label"
    >
      <validation-provider
        #default="{ errors }"
        :name="fieldsData.label"
        :rules="fieldsData.required ? 'required' : ''"
      >
        <b-form-input
          v-if="showTextField(fieldsData.type)"
          v-model="fieldsData.value"
          :name="fieldsData.name"
          :disabled="fieldsData.disabled"
          :state="errors.length > 0 ? false : null"
          @change="$emit('change')"
        />

        <b-form-textarea
          v-if="fieldsData.type === 'long_text'"
          id="textarea-default"
          v-model="fieldsData.value"
          :name="fieldsData.name"
          rows="3"
          :disabled="fieldsData.disabled"
          :state="errors.length > 0 ? false : null"
          @change="$emit('change')"
        />

        <b-form-datepicker
          v-if="fieldsData.type === 'date'"
          id="example-datepicker"
          v-model="fieldsData.value"
          class="mb-1"
          :disabled="fieldsData.disabled"
          :state="errors.length > 0 ? false : null"
          @change="$emit('change')"
        />

        <b-form-select
          v-if="fieldsData.type === 'dropdown'"
          v-model="fieldsData.value"
          :options="fieldsData.options"
          :disabled="fieldsData.disabled"
          :class="{'is-invalid': errors.length > 0 }"
          @change="$emit('change')"
        />

        <v-select
          v-if="fieldsData.type === 'multi_select'"
          v-model="fieldsData.value"
          placeholder="Select"
          multiple
          :close-on-select="false"
          :options="fieldsData.options"
          :deselect-from-dropdown="true"
          :class="{'is-invalid': errors.length > 0 }"
          @input="$emit('change')"
        />

        <template
          v-if="fieldsData.type === 'checkbox'"
        >
          <b-form-checkbox-group
            v-model="fieldsData.value"
            :options="fieldsData.options"
            class="py-1"
            :disabled="fieldsData.disabled"
            @change="$emit('change')"
          />
        </template>

        <template
          v-if="fieldsData.type === 'radio_button'"
        >
          <b-form-radio-group
            v-model="fieldsData.value"
            :options="fieldsData.options"
            class="py-1"
            :disabled="fieldsData.disabled"
            @change="$emit('change')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </template>

        <template
          v-if="fieldsData.type === 'staff'"
        >
          <v-select
            v-model="fieldsData.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="staffList"
            :clearable="true"
            :placeholder="'Select Staff'"
            :class="{'is-invalid': errors.length > 0 }"
            class="staff-selection"
            @search="handleSearchOnStaffType"
            @input="$emit('change')"
          >
            <li
              v-if="staffList.length && staffListMetaData.currentPage"
              slot="list-footer"
              class="pagination d-flex align-items-center justify-content-between my-1 mx-2"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                :disabled="!staffListMetaData.hasPrevPage"
                @click="handlePaginationOnStaffType(staffListMetaData.currentPage - 1)"
              >
                Prev
              </b-button>
              <span class="pages">{{ staffListMetaData.currentPage }} of {{ staffListMetaData.lastPage }}</span>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                :disabled="!staffListMetaData.hasNextPage"
                @click="handlePaginationOnStaffType(staffListMetaData.currentPage + 1)"
              >
                Next
              </b-button>
            </li>
          </v-select>
        </template>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

  </b-col>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { appGroupCustomFieldRowFromType } from '@/store/modules/app-groups/group-custom-field-row-from-type'

export default {
  components: {
    BButton,
    ValidationProvider,

    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormRadioGroup,

    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [formBuilderPreparations],
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isLoading() {
      return appGroupCustomFieldRowFromType.getters.getIsLoading
    },
    staffList() {
      return appGroupCustomFieldRowFromType.getters.getStaffList
    },
    staffListMetaData() {
      return appGroupCustomFieldRowFromType.getters.getStaffListMetaData
    },
  },
  methods: {
    showTextField(type) {
      // eslint-disable-next-line no-bitwise
      return ~['text', 'email', 'tel', 'number'].findIndex(item => item === this.transformField(type))
    },
    async handleSearchOnStaffType(query) {
      this.$nextTick(() => {
        const modifiedQuery = query.trim()
        appGroupCustomFieldRowFromType.actions.searchStaff(modifiedQuery)
      })
    },
    async handlePaginationOnStaffType(page) {
      this.$nextTick(() => {
        appGroupCustomFieldRowFromType.actions.makePagination(page)
      })
    },
  },
}
</script>

<style scoped>
.staff-selection .pagination .btn {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
}
.staff-selection .pagination .pages {
  font-size: 0.75rem;
}
</style>
