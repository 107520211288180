import Vue from 'vue'
import VueRouter, { NavigationGuard } from 'vue-router'
import { Dictionary } from 'vue-router/types/router.d'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, setCurrentUserData,
  isCurrentProgramIdEqualsQueryProgramId, isCurrentSchoolIdEqualsQuerySchoolId,
  changeDefaultProgramId, switchSchoolAndProgram,
} from '@/auth/utils'
import pages from './routes/pages'
import admin from './routes/admin/admin'
import staff from './routes/staff/staff'

Vue.use(VueRouter)

const userData = getUserData()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return null
    }
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: getHomeRouteForLoggedInUser(userData?.role) },
    ...admin,
    ...staff,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  const querySchoolId = to.query.school_id
  const queryProgramId = to.query.program_id

  if (to.meta.specialTitle && typeof to.meta.specialTitle === 'function') {
    // eslint-disable-next-line no-param-reassign
    to.meta.pageTitle = to.meta.specialTitle(to)
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (querySchoolId && queryProgramId && !isCurrentSchoolIdEqualsQuerySchoolId(querySchoolId)) {
    switchSchoolAndProgram(querySchoolId, queryProgramId)
    return next()
  }

  if (queryProgramId && !isCurrentProgramIdEqualsQueryProgramId(queryProgramId)) {
    changeDefaultProgramId(queryProgramId)
    return next()
  }

  // eslint-disable-next-line eqeqeq
  if (querySchoolId && queryProgramId) {
    return next()
  }

  if (to.meta.requiresSchoolAndProgram && !querySchoolId) {
    const defaultProgram = localStorage.getItem('defaultProgram') || ''
    const currentSchoolId = localStorage.getItem('currentSchoolId') || ''

    const query: Dictionary<string> = {}
    if (currentSchoolId) query.school_id = currentSchoolId
    if (defaultProgram) query.program_id = defaultProgram
    if (query.school_id) {
      const resolvedRoute = {
        ...to,
        name: to.name || undefined,
        query: {
          ...to.query,
          ...query,
        },
      }
      return next(resolvedRoute)
    }
    return next()
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async () => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  const isLoggedIn = isUserLoggedIn()

  if (isLoggedIn) {
    await setCurrentUserData()
  }
})

export default router
