import axios from '@axios'

export const fetchSessionsForSelect = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/sessions-for-select', { params })
  return res
}

export const fetchSessionsList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/sessions', { params })
  return res
}

export const _ = null
