<template>
  <div>

    <group-type-auto-assign-row
      v-for="(item, index) in assignsList"
      :key="index"
      class="mt-2"
      :assign-data="item"
      :index="index"
      :custom-fields-list="customFieldsList"
      :student-ppa-list="studentPpaList"
      @removeAssignsRow="removeAssignsRow"
      @change="$emit('change')"
    />

    <b-button
      variant="outline-primary"
      class="d-flex align-items-center justify-content-center w-100 mt-2"
      @click="addNewAssignsRow"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="mr-1"
      />
      Add Auto-Assignment
    </b-button>

    <info-modal
      :modal-name="infoModalName"
      :title="''"
      :message="'Please add the custom field first'"
    />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import GroupTypeAutoAssignRow from '@/views/admin/group/group-forms/components/GroupTypeAutoAssignRow.vue'
import InfoModal from '@/views/components/info-modal/InfoModal.vue'
import useAutoAssigns from '@/views/admin/group/group-forms/components/useAutoAssigns'

export default {
  components: {
    BButton,

    GroupTypeAutoAssignRow,
    InfoModal,
  },
  props: {
    assignsList: {
      type: Array,
      default: () => {
      },
    },
    customFieldsList: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      studentPpaList: [],
      infoModalName: 'assigns',
    }
  },
  setup() {
    const {
      fetchPpaList,
    } = useAutoAssigns()

    return {
      fetchPpaList,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
  },
  async created() {
    this.studentPpaList = await this.fetchPpaList({ program_id: this.programId })
  },
  methods: {
    addNewAssignsRow() {
      if (this.customFieldsList.length === 0) {
        this.$bvModal.show(`info${this.infoModalName}`)
      } else {
        this.assignsList.push({
          studentPpa: null,
          operator: null,
          customField: null,
        })
      }
    },
    removeAssignsRow(index) {
      this.assignsList.splice(index, 1)
      this.$emit('change')
    },
  },
}
</script>
