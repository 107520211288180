<template>
  <b-list-group-item
    tag="div"
    class="d-flex justify-content-start"
  >
    <b-card-text
      v-if="fieldsData.type === 'step'"
    >
      <h2 class="mt-2">
        {{ fieldsData.label }}
      </h2>
    </b-card-text>

    <b-card-text
      v-else-if="fieldsData.type === 'plain_text'"
    >
      {{ fieldsData.label }}
    </b-card-text>

    <template
      v-else
    >

      <!-- Label -->
      <b-col
        md="3"
        xl="3"
      >
        <b-card-text>
          <span class="w-100 bv-no-focus-ring col-form-label pt-0">
            Field
          </span>
          <h5 class="mt-2">
            {{ fieldsData.label }}
          </h5>
        </b-card-text>
      </b-col>

      <!-- Input Name -->
      <b-col
        md="6"
        xl="6"
      >
        <b-form-group
          label="Result"
        >
          <b-form-input
            v-if="showTextField(fieldsData.type)"
            v-model="fieldsData.value"
            :name="fieldsData.name"
            :type="fieldsData.type"
            :required="fieldsData.required"
            size="lg"
            :disabled="fieldsData.disabled"
          />

          <b-form-textarea
            v-if="fieldsData.type === 'long_text'"
            id="textarea-default"
            v-model="fieldsData.value"
            :name="fieldsData.name"
            :required="fieldsData.required"
            rows="3"
            :disabled="fieldsData.disabled"
          />

          <b-form-datepicker
            v-if="fieldsData.type === 'date'"
            id="example-datepicker"
            v-model="fieldsData.value"
            :required="fieldsData.required"
            class="mb-1"
            :disabled="fieldsData.disabled"
          />

          <b-form-select
            v-if="fieldsData.type === 'dropdown'"
            v-model="fieldsData.value"
            :options="fieldsData.options"
            :required="fieldsData.required"
            size="lg"
            :disabled="fieldsData.disabled"
          />

          <b-form-select
            v-if="fieldsData.type === 'multi_select'"
            v-model="fieldsData.value"
            :options="fieldsData.options"
            multiple
            :required="fieldsData.required"
            size="lg"
            :disabled="fieldsData.disabled"
          />

          <template
            v-if="fieldsData.type === 'checkbox'"
          >
            <b-form-checkbox-group
              v-model="fieldsData.value"
              :options="fieldsData.options"
              class="py-1"
              :disabled="fieldsData.disabled"
            />
          </template>

          <template
            v-if="fieldsData.type === 'radio_button'"
          >
            <b-form-radio-group
              v-model="fieldsData.value"
              :options="fieldsData.options"
              class="py-1"
              :disabled="fieldsData.disabled"
            />
          </template>

          <b-form-file
            v-if="fieldsData.type === 'file_upload'"
            v-model="fieldsData.value"
            multiple
            :required="fieldsData.required"
            size="lg"
            :file-name-formatter="formatNames"
          />

        </b-form-group>

      </b-col>

      <!-- Input Name -->
      <b-col
        md="1"
        xl="1"
      >

        <b-form-group
          label=" "
        >
          <SquareIcon
            v-if="!fieldsData.readonly"
            size="21"
            icon="EditIcon"
            class="cursor-pointer mt-2"
            @onIconClick="editField"
          />
        </b-form-group>

      </b-col>

    </template>
  </b-list-group-item>
</template>

<script>
import {
  BCol,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormFile,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'

import useProgramsSetting from '@/views/admin/program/useProgramsSetting'

import store from '@/store'
import storeModule from '@/views/admin/group/groupStoreModule'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  components: {
    BCol,
    BCardText,
    BFormGroup,
    BListGroupItem,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormFile,

    SquareIcon,
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      formBuilderFieldTypes,
    } = useProgramsSetting()

    return {
      formBuilderFieldTypes,
    }
  },
  methods: {
    showTextField(type) {
      // eslint-disable-next-line no-bitwise
      return ~['text', 'email', 'tel', 'number'].findIndex(item => item === type)
    },
    formatNames(files) {
      if (files.length === 1) return files[0].name

      return `${files.length} files selected`
    },
    editField() {
      this.fieldsData.disabled = false
    },
  },
}
</script>

<style scoped>

</style>
