<template>
  <b-row
    class="px-1"
  >
    <!-- Auto-remove -->
    <b-col
      sm="12"
      class="mt-2"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.auto_removing"
          name="checkbox-1"
          :disabled="!assignsList.length"
          @change="$emit('change')"
        >
          Auto-remove if auto-assign conditions are not met anymore
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Recalculate the balance -->
    <b-col
      v-if="groupData.auto_removing"
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.recalculate_balance"
          name="checkbox-1"
          @change="$emit('change')"
        >
          Recalculate the balance with charge after auto-remove
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Tuition-->
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.has_tuition_fee"
          name="checkbox-1"
          :disabled="['CreateGroup', 'Edit'].includes(action)"
          @change="$emit('change')"
        >
          Tuition
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Visible -->
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.is_visible"
          name="checkbox-1"
          @change="isVisibleChanged"
        >
          Visible
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Allow User Selection -->
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.is_allow_use_selection"
          name="checkbox-1"
          :disabled="!groupData.is_visible"
          @change="isAllowUseSelectionChanged"
        >
          Allow User Selection
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Restrict to Single Group of X Type -->
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.is_restrict_single_group_type"
          name="checkbox-1"
          :disabled="!groupData.is_allow_use_selection"
          @change="$emit('change')"
        >
          Restrict to Single Group of X Type
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Capacity Enforcement -->
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <validation-provider
          rules=""
          vid="capacity-enforcement"
        >
          <b-form-checkbox
            v-model="groupData.is_capacity_enforcement"
            name="checkbox-1"
            :disabled="!groupData.is_allow_use_selection"
            @change="$emit('change')"
          >
            Capacity Enforcement
          </b-form-checkbox>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      v-if="groupData.is_capacity_enforcement"
      sm="12"
      class="mt-1"
    >
      <b-form-group
        label="Capacity Number"
        label-for="capacity"
        label-cols-lg="6"
        content-cols-lg="6"
        class="col-form-label-sm"
      >
        <validation-provider
          #default="{ errors }"
          name="capacity"
          :rules="{ required: groupData.is_capacity_enforcement }"
        >
          <b-form-input
            v-model="groupData.capacity"
            placeholder="0"
            :state="errors.length > 0 ? false : null"
            @change="$emit('change')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Allow viewing other group members -->
    <b-col
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <b-form-checkbox
          v-model="groupData.is_allow_viewing_other_members"
          name="checkbox-1"
          :disabled="!groupData.is_allow_use_selection"
          @change="$emit('change')"
        >
          Allow viewing other group members
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <b-col
      v-if="groupData.is_allow_viewing_other_members"
      sm="12"
    >
      <b-form-group
        label="Visible Fields"
        class="mb-0"
      >
        <validation-provider
          #default="{ errors }"
          name="Visible Fields"
          :rules="{ required: groupData.is_allow_viewing_other_members }"
        >
          <b-badge
            v-for="(field, index) in groupData.visible_members_fields"
            :key="index"
            class="mb-1 mr-1"
            variant="primary"
          >
            <span class="text-break">{{ field }}</span>
            <feather-icon
              icon="XIcon"
              size="18"
              class="ml-1 cursor-pointer"
              @click="removeVisibleField(index)"
            />
          </b-badge>
          <v-select
            v-model="groupData.visible_members_fields"
            multiple
            class="mb-2"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ppaFieldslist"
            placeholder="Select"
            :clearable="false"
            :close-on-select="false"
            @input="$emit('change')"
          >
            <template
              #selected-option-container="{ option }"
            >
              <div
                style="display: none"
              >{{ option }}</div>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <!-- Limit Selection-->
    <template
      v-if="onlyGroupTypeRule"
    >
      <b-col
        sm="12"
        class="mt-1"
      >
        <b-form-group>
          <b-form-checkbox
            v-model="groupData.is_limit_selection"
            name="checkbox-1"
            :disabled="!groupData.is_allow_use_selection"
            @change="$emit('change')"
          >
            Limit Selection
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col
        v-if="groupData.is_limit_selection"
        cols="12"
      >
        <group-type-limit-selections
          :conditions-list="groupData.limit_selection_conditions"
          :custom-fields-list="customFieldsList"
        />
      </b-col>
    </template>

    <!-- Bulk Create -->
    <b-col
      v-if="isShowAdditionalInputs"
      sm="12"
      class="mt-1"
    >
      <b-form-group>
        <validation-provider
          rules=""
          vid="bulk_create"
        >
          <b-form-checkbox
            v-model="groupData.is_bulk_create"
            name="checkbox-1"
          >
            Bulk Create
          </b-form-checkbox>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      v-if="groupData.is_bulk_create && isShowAdditionalInputs"
      sm="12"
      class="mt-1"
    >
      <b-form-group
        label="A number of identical groups"
        label-for="groups-count"
        label-cols-lg="6"
        content-cols-lg="6"
        class="col-form-label-sm"
      >
        <validation-provider
          #default="{ errors }"
          name="groups-count"
          :rules="{ required: groupData.is_bulk_create }"
        >
          <b-form-input
            v-model="groupData.new_groups_count"
            placeholder="0"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCol, BFormCheckbox, BFormGroup, BFormInput, BRow, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { requiredIf } from '@validations'
import vSelect from 'vue-select'
import useAdminRules from '@/views/admin/group/group-forms/components/useAdminRules'
import GroupTypeLimitSelections from '@/views/admin/group/group-forms/components/GroupTypeLimitSelections.vue'

export default {
  name: 'AdminRulesComponent',
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BBadge,

    ValidationProvider,

    vSelect,
    GroupTypeLimitSelections,
  },
  props: {
    groupData: {
      type: Object,
      required: true,
    },
    customFieldsList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    onlyGroupTypeRule: {
      type: Boolean,
      default: false,
    },
    assignsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      requiredIf,
      ppaFieldslist: [],
    }
  },
  setup() {
    const {
      fetchPpaList,
    } = useAdminRules()

    return {
      fetchPpaList,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
    isShowAdditionalInputs() {
      return this.type === 'Group'
    },
  },
  async created() {
    this.ppaFieldslist = await this.fetchPpaList({ program_id: this.programId })
  },
  methods: {
    isVisibleChanged(val) {
      if (val === false) {
        this.setBaseRulesParams()
      }
      this.$emit('change')
    },
    isAllowUseSelectionChanged(val) {
      if (val === false) {
        this.setBaseRulesParams()
      }
      this.$emit('change')
    },
    setBaseRulesParams() {
      this.groupData.is_allow_use_selection = false
      this.groupData.is_restrict_single_group_type = false
      this.groupData.is_capacity_enforcement = false
      this.groupData.is_allow_viewing_other_members = false
      this.groupData.visible_members_fields = []
      this.groupData.is_limit_selection = false
      this.groupData.limit_selection_conditions = []
      this.groupData.is_bulk_create = false
      this.groupData.capacity = 0
      this.groupData.new_groups_count = 0
    },
    removeVisibleField(index) {
      this.groupData.visible_members_fields.splice(index, 1)
      this.$emit('change')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
