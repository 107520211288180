import axios from '@axios'

export const fetchFile = async (filePath: string): Promise<any> => {
  const response = await axios.get('/auth/file/download', {
    params: { file_path: filePath },
    responseType: 'blob',
  })
  return response.data
}

export const uploadFile = async (id: string, file: any): Promise<any> => {
  const response = await axios
    .post(`/auth/file/upload/${id}`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

  return response.data
}

export const removeFile = async (params?: any): Promise<any> => {
  const response = await axios
    .post('/auth/file/remove', params)

  return response.data
}

export const fetchFileFoldersList = async (params?: any): Promise<any> => {
  const response = await axios.get('/auth/file-folders', { params })
  return response
}

export const fetchFilesByFolder = async (fileFolderName: string, params?: any): Promise<any> => {
  const response = await axios.get(`/auth/file-folders/${fileFolderName}`, { params })
  return response
}
export const _ = null
