




































































































































































import Vue from 'vue'

import {
  BTable,
  BLink,
  BRow, BCol,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'
import SelectPpaColumns from './modals/SelectPpaColumns.vue'
import TagsEditSelector from '@/views/components/tags-edit-selector/TagsEditSelector.vue'
import { PER_PAGE_OPTIONS } from '@/constants/base'
import { permissionSubjects } from '@/libs/acl/constants'
import { appGroupsList } from '@/store/modules/app-groups/app-groups-list'
import { IGroupUsersState, IGroupUserItemState } from '@/store/modules/app-groups/app-groups-list/types'

export default Vue.extend({
  name: 'GroupUsers',
  components: {
    BTable,
    BLink,
    BRow,
    BCol,
    BPagination,
    BOverlay,

    vSelect,
    TableSpinner,

    BadgeTagList,
    SelectPpaColumns,
    TagsEditSelector,
  },
  filters: {
    fixTableHeaderView(val: string) {
      return val.split('.').join(': ').replace(/_/g, ' ')
    },
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    isAllExpanded: {
      type: Boolean,
      required: true,
    },
    groupUsers: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data(): IGroupUsersState {
    return {
      isLoading: false,
      perPage: 25,
      totalItems: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPageOptions: PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [],
      tableItems: [],
      permissionSubjects,
    }
  },
  computed: {
    ppaList() {
      return appGroupsList.getters.getPpaList
    },
    tagSetOptionsList() {
      return appGroupsList.getters.getTagSetsOptionsList
    },
    localStorageItemName(): string {
      return `groupPpaColumns-${this.programId}`
    },
  },
  watch: {
    perPage() {
      this.fetchGroupUsersList()
    },
    currentPage() {
      this.fetchGroupUsersList()
    },
  },
  mounted() {
    this.setTableData()
  },
  methods: {
    getUserViewRoutByType(item: IGroupUserItemState) {
      if (item.is_student) {
        return { name: 'admin-application-edit', params: { id: item.application_id, type: this.camperStudent(1) } }
      }
      return { name: 'admin-application-edit', params: { id: item.application_id, type: 'Staff' } }
    },
    setTableData() {
      if (this.isAllExpanded && this.groupUsers) {
        this.tableItems = this.groupUsers.data
        const { total, from, to } = this.groupUsers.meta
        this.totalItems = total
        this.dataMeta = {
          from,
          to,
          of: total,
        }
        this.setTableColumns(this.groupUsers.data)
      } else {
        this.fetchGroupUsersList()
      }
    },
    async fetchGroupUsersList() {
      try {
        this.isLoading = true
        const item = localStorage.getItem(this.localStorageItemName)

        const queryParams = {
          group_id: this.groupId,
          program_id: this.programId,
          perPage: this.perPage,
          page: this.currentPage,
          ppa: item ? JSON.parse(item) : [],
        }

        const response = await appGroupsList.actions.fetchGroupUsersExtraDetailsList(queryParams)
        const { total, from, to } = response.data.meta
        this.totalItems = total
        this.dataMeta = {
          from,
          to,
          of: total,
        }
        this.tableItems = response.data.data
        this.setTableColumns(response.data.data)

        return response.data.data
      } catch (error) {
        return []
      } finally {
        this.isLoading = false
      }
    },
    setTableColumns(data: IGroupUserItemState[]) {
      if (data[0]) {
        this.tableColumns = Object.keys(data[0])
          .filter(item => !['application_id', 'is_student'].includes(item))
      } else {
        this.tableColumns = []
      }
    },
    updateTableItemTags(applicationId: string, tags: []) {
      const item = this.tableItems.find((item: IGroupUserItemState) => item.application_id === applicationId)
      if (item) {
        (item as IGroupUserItemState).tags = tags
      }
      this.$forceUpdate()
    },
    checkIfSlotValueIsBase64File(item: any) {
      if (item) {
        const matches = item.toString().match(/^data:image\/(\w+);base64,/)
        if (!matches) {
          return false
        }
        return matches.length > 0
      }
      return false
    },
  },
})
