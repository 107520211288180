import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTaskEdit() {
  // Use toast
  const toast = useToast()

  const updateApplicationTask = queryParams => axios
    .post('/auth/application-tasks/update-result', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Task was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error update Application Task Data',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchApplicationTask = ID => axios
    .get(`/auth/application-tasks/${ID}/edit`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching application task',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Accepted') return 'success'
    if (status === 'Rejected') return 'danger'
    return 'primary'
  }

  return {
    updateApplicationTask,
    fetchApplicationTask,
    resolveStatusVariant,
  }
}
