























































































































































































































































































































import Vue from 'vue'

import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BRow,
  BCollapse,
  BFormInput,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { appGroupBulkEdit } from '@/store/modules/app-groups/app-group-bulk-edit'
import { IGeneralSettingsState, IGroupItemState } from '@/store/modules/app-groups/app-group-bulk-edit/types'
import GroupCustomFieldRowFromType from '@/views/admin/group/group-forms/components/GroupCustomFieldRowFromType.vue'
import GroupCustomFields from '@/views/admin/group/group-forms/components/GroupCustomFields.vue'
import GroupTypeAutoAssigns from '@/views/admin/group/group-forms/components/GroupTypeAutoAssigns.vue'
import GroupAdminRulesComponent from '@/views/admin/group/group-forms/components/GroupAdminRulesComponent.vue'

interface GroupBulkEditIndividualSettingsData {
  visible: boolean,
  collapseItemID: string,
  openOnHover: boolean,
  isCustomPricingAvailable: boolean,
}
export default Vue.extend({
  name: 'GroupBulkEditIndividualSettings',
  components: {
    GroupAdminRulesComponent,
    GroupTypeAutoAssigns,
    GroupCustomFields,
    GroupCustomFieldRowFromType,
    ValidationProvider,
    vSelect,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BFormGroup,
    BFormInput,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCollapse,
    BTab,
    BTabs,
  },
  data(): GroupBulkEditIndividualSettingsData {
    return {
      visible: true,
      collapseItemID: uuidv4(),
      openOnHover: (this.$parent as any).hover,
      isCustomPricingAvailable: true,
    }
  },
  computed: {
    generalSettings: {
      get(): IGeneralSettingsState {
        return appGroupBulkEdit.getters.getGeneralSettings
      },
      set(val: IGeneralSettingsState) {
        appGroupBulkEdit.mutations.SET_GENERAL_SETTINGS(val)
      },
    },
    groupStatusOptions() {
      return appGroupBulkEdit.getters.getGroupStatusOptions
    },
    groupSessionsOptions() {
      return appGroupBulkEdit.getters.getSessionsList
    },
    groupsList() {
      return appGroupBulkEdit.getters.getItems
    },
    haveDifferentGroupTypes() {
      return appGroupBulkEdit.getters.getHaveDifferentGroupTypes
    },
    groupTypeFieldsList() {
      return appGroupBulkEdit.getters.getGroupTypeFieldsList
    },
  },
  methods: {
    updateVisible(val: boolean) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    markFieldAsModified(group: IGroupItemState, field: string) {
      if (!group.modifiedFields) {
        // eslint-disable-next-line no-param-reassign
        group.modifiedFields = []
      }
      if (!group.modifiedFields.includes(field)) {
        // eslint-disable-next-line no-param-reassign
        group.modifiedFields.push(field)
      }

      // eslint-disable-next-line no-param-reassign
      group.isModified = true
    },
  },
})
