import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  actions: {
    fetchGroupList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/groups', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypesForSelect(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types-for-select', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/group-types/${queryParams.id}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    fetchParentGroup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/groups-by-type/${queryParams.id}`, {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/sessions', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportCampers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/campers/export-with-group', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveBulkGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/save-bulk-groups', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importBulkAssignGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/groups-bulk-assign', formData)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    importBulkCreateGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/groups-bulk-create', formData)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    async fetchPpaList(queryParams) {
      try {
        return await axios.get('/auth/ppa/names', {
          params: queryParams,
        })
      } catch (error) {
        return error
      }
    },
  },
}
