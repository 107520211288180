import axios from '@axios'
import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useApplicationTaskList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'selected', label: 'selected', sortable: false },
    { key: 'user_name', label: 'user', sortable: false },
    { key: 'program_name', label: 'program', sortable: false },
    { key: 'status', label: 'status', sortable: true },
    { key: 'date', label: 'due date', sortable: false },
    { key: 'actions', label: 'actions', sortable: false },
  ]

  const defaultPerPage = Number(localStorage.getItem('applicationTasksPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterDataUserType = ref('')
  const filterDataProgram = ref('')
  const filterDataStatus = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const taskId = ref(null)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataUserType.value = ''
    filterDataProgram.value = ''
    filterDataStatus.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterDataUserType, filterDataProgram, filterDataStatus],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataUserTypeVal, filterDataProgramVal, filterDataStatusVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataUserTypePrevVal, filterDataProgramPrevVal, filterDataStatusPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'application-tasks', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'application-tasks', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'application-tasks', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataUserTypeVal !== filterDataUserTypePrevVal) {
        pushStateFiltersParams(root, 'application-tasks', 'filterDataUserType', filterDataUserTypeVal)
      }
      if (filterDataProgramVal !== filterDataProgramPrevVal) {
        pushStateFiltersParams(root, 'application-tasks', 'filterDataProgram', filterDataProgramVal)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'application-tasks', 'filterDataStatus', filterDataStatusVal)
      }
    })

  const fetchTaskList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      hasTask: true,
      hasProgram: true,
      taskId: taskId.value,
    }

    if (filterDataUserType.value) payloadData.userRole = filterDataUserType.value
    if (filterDataStatus.value) payloadData.status = filterDataStatus.value
    if (filterDataProgram.value) payloadData.programId = filterDataProgram.value
    if (query.searchQuery) payloadData.userName = query.searchQuery
    store
      .dispatch('app-application-tasks/fetchApplicationTaskList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Application Task list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchApplicationTask = taskID => axios
    .get(`/auth/application-tasks/${taskID}/edit`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Task',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateApplicationTask = queryParams => axios
    .post('/auth/application-tasks/update-result', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Task was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error update Application Task Data',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchPrograms = queryParams => axios
    .get('/auth/programs', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Programs',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Completed') return 'success'
    if (status === 'Overdue') return 'danger'
    if (status === 'Manual Deleted') return 'warning'
    return 'primary'
  }

  return {
    fetchTaskList,
    programId,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterDataUserType,
    filterDataProgram,
    filterDataStatus,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,
    taskId,
    resolveStatusVariant,

    clearFilters,
    refetchData,

    fetchApplicationTask,
    updateApplicationTask,
    fetchPrograms,
  }
}
