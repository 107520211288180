<template>

  <b-row>

    <!-- Left Col: Card -->
    <b-col
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions"
    >

      <b-badge
        variant="primary"
        class="d-block p-75 mb-1"
      >
        <span class="mb-0 text-white">{{ taskDetail.name }}</span>
      </b-badge>

      <b-card
        no-body
      >

        <b-card-header class="d-flex justify-content-between align-items-center">
          <b-link
            v-if="type === camperStudent(1)"
            :to="{ name: 'admin-camper-edit', params: { id: camper.id } }"
          >{{ type }} Profile</b-link>
          <b-link
            v-if="type === 'Staff'"
            :to="{ name: 'admin-staff-profile', params: { id: userInfo.id } }"
          >{{ type }} Profile</b-link>
        </b-card-header>

        <b-card-body>
          <ul class="list-unstyled">
            <li>
              <span class="align-middle">Name: {{ userInfo.full_name }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">DOB: {{ userInfo.extra_user_info.birth_date }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Gender: {{ userInfo.extra_user_info.gender }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Language: {{ userInfo.extra_user_info.language }}</span>
            </li>
            <li
              v-if="type === camperStudent(1)"
              class="my-25"
            >
              <span class="align-middle">Parent Profile:
                <b-link
                  :to="{ name: 'admin-parent-profile', params: { id: camper.user_parent_id } }"
                >{{ camper.parent_name }}</b-link>
              </span>
            </li>
            <li
              v-if="type === camperStudent(1)"
              class="my-25"
            >
              <span class="align-middle">Sessions:
                <span
                  v-for="session in sessionsList"
                  :key="session.id"
                > {{ session.name }}</span>
              </span>
            </li>
            <li>
              <span class="align-middle">Status:
                <b-badge
                  pill
                  :variant="`light-${resolveStatusVariant(applicationTaskStatus)}`"
                >
                  {{ applicationTaskStatus }}
                </b-badge>
              </span>
            </li>
          </ul>
        </b-card-body>

      </b-card>

    </b-col>

    <!-- Right Col: -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >
      <!-- Loading -->
      <b-card
        v-if="isLoading"
      >
        Loading...
      </b-card>

      <!-- Table Container Card -->
      <b-card
        v-else
        no-body
      >
        <FormResultEditRow
          v-for="(item, index) in fieldsList"
          :key="item.frontID"
          :fields-data="item"
          :field-index="index"
          :files-for-remove="filesForRemove"
        />

        <!-- <FormResultGroupRow
          v-for="(groups, groupType) in groupsList"
          :key="groupType"
          :groups-data="groups"
          :group-type="groupType"
        /> -->
        <div
          class="card-footer d-flex justify-content-end"
        >
          <div>
            <b-button
              variant="primary"
              class="mr-2"
              @click="saveForm"
            >
              Save Changes
            </b-button>
            <b-button
              variant="outline-primary"
              @click="resetForm"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </b-card>

    </b-col>

  </b-row>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BBadge,
  BButton,
  BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/tasks/taskStoreModule'
import useApplicationTaskEdit from '@/views/admin/tasks/task-edit/useTaskEdit'
import router from '@/router'

import FormResultEditRow from '@/views/admin/forms/form-result-edit/FormResultEditRow.vue'
import { removeFile, uploadFile } from '@/api/files'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BLink,

    FormResultEditRow,
  },
  directives: {
    Ripple,
  },
  mixins: [formBuilderPreparations],
  data() {
    return {
      isLoading: true,
      applicationTaskID: null,
      taskDetail: {},
      defaultFieldsList: [],
      fieldsList: [],
      type: null,
      camper: null,
      userInfo: {
        extra_user_info: {},
      },
      fieldsValue: [],
      applicationTaskStatus: null,
      groupsList: [],
      sessionsList: null,
      filesForRemove: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-application-tasks'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      updateApplicationTask,
      fetchApplicationTask,
      resolveStatusVariant,
    } = useApplicationTaskEdit()

    return {
      updateApplicationTask,
      fetchApplicationTask,
      resolveStatusVariant,
    }
  },
  async created() {
    this.applicationTaskID = router.currentRoute.params.id

    if (this.applicationTaskID) {
      await this.fetchApplicationTask(this.applicationTaskID).then(response => {
        this.applicationTaskStatus = response.status
        this.taskDetail = response.task
        this.fieldsValue = response.task_results
        this.camper = response.camper
        this.userInfo = response.user
        this.type = this.camper ? this.camperStudent(1) : 'Staff'

        this.defaultFieldsList = response.form.fields
          .map(row => {
            if (row.type === 'file_upload') {
              return {
                id: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id,
                field_id: row.id,
                value: null,
                files: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.files || [],
                label: row.label,
                name: row.name,
                options: this.designateOptions(row.type, row.options),
                type: this.transformField(row.type),
                required: row.required,
                disabled: true,
              }
            }
            return {
              id: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id,
              field_id: row.id,
              value: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.result,
              label: row.label,
              name: row.name,
              options: this.designateOptions(row.type, row.options),
              type: this.transformField(row.type),
              required: row.required,
              disabled: true,
            }
          })
        this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))

        this.sessionsList = response.sessions
        this.isLoading = false
      })
    }
  },
  methods: {
    fileUpload(file) {
      const formData = new FormData()
      formData.append('file', file)
      return uploadFile(`tasks-files-${new Date().getTime()}`, formData)
    },
    resetForm() {
      this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))
      this.$router.back()
    },
    async saveForm() {
      // Remove old files
      if (this.filesForRemove.length > 0) {
        this.filesForRemove.flat(Infinity).forEach(file => {
          removeFile({ file_path: file.path })
        })
      }

      const queryParams = {
        form_id: this.taskDetail.form.id,
        application_task_id: this.applicationTaskID,
        fields: [],
        form_data: {},
      }

      let fileFieldsCount = 0
      const filesLinks = []
      const filesNames = []

      const fileFields = this.fieldsList.filter(row => row.type === 'file_upload' && row.value)

      if (fileFields.length > 0) {
        fileFields.forEach(file => {
          Array.from(file.value).forEach(val => {
            filesLinks.push(this.fileUpload(val))
          })
        })
      }

      Promise.all(filesLinks)
        .then(responses => responses.forEach(
          response => {
            filesNames.push(response.file_path)
          },
        ))
        .finally(() => {
          this.fieldsList.forEach(row => {
            if (row.type === 'file_upload' && row.value) {
              queryParams.fields.push({
                id: row.id,
                field_id: row.field_id,
                files: filesNames.slice(fileFieldsCount, row.value.length),
              })
              queryParams.form_data[row.name] = filesNames.slice(fileFieldsCount, row.value.length)
              fileFieldsCount += 1
            } else {
              queryParams.fields.push({
                id: row.id,
                field_id: row.field_id,
                files: row.files,
                result: row.value,
              })
              queryParams.form_data[row.name] = row.value
            }
          })

          this.updateApplicationTask(queryParams)
          this.$router.back()
        })
    },
  },
}
</script>

<style scoped>
</style>
