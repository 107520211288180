






























import Vue from 'vue'

import {
  BModal,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

interface ISelectPpaColumnsState {
  selectedPpa: string[],
}
export default Vue.extend({
  name: 'SelectPpaColumns',
  components: {
    BModal,
    BFormGroup,

    vSelect,
  },
  props: {
    ppaList: {
      type: Array,
      required: true,
    },
  },
  data(): ISelectPpaColumnsState {
    return {
      selectedPpa: [],
    }
  },
  computed: {
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    localStorageItemName(): string {
      return `groupPpaColumns-${this.programId}`
    },
  },
  methods: {
    setModalData() {
      const item = localStorage.getItem(this.localStorageItemName)
      const localStorageColumns = item ? JSON.parse(item) : []
      if (localStorageColumns) {
        this.selectedPpa = localStorageColumns
      } else {
        this.selectedPpa = []
      }
    },
    savePpaColumns() {
      localStorage.setItem(this.localStorageItemName, JSON.stringify(this.selectedPpa))
      this.$emit('updateData')
    },
  },
})
