import axios from '@axios'

export const fetchGroupTypesForSelect = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/group-types-for-select', { params })
  return res
}

export const fetchGroupTypesList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/group-types', { params })
  return res
}

export const fetchGroupType = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/group-types/${id}`)
  return res
}

export const updateGroupType = async (params: any): Promise<any> => {
  const res = await axios.post(`/auth/group-types/${params.id}`, params)
  return res
}

export const deleteGroupType = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/group-types/${id}`)
  return res
}

export const _ = null
