import Vue from 'vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { fetchTagSetsOptionsList } from '@/api/tags'

export default {
  namespaced: true,
  state: {
    applicationId: '',
    actionType: 'add',
    selectedTags: [],
    selectedTag: null,
    tagSetOptionsList: [],
    tagStatistics: [],
    applicationsList: [],
    isLoading: false,
  },
  getters: {
    getApplicationId: state => state.applicationId,
    getActionType: state => state.actionType,
    getSelectedTags: state => state.selectedTags,
    getSelectedTag: state => state.selectedTag,
    getTagSetOptionsList: state => state.tagSetOptionsList,
    getTagStatistics: state => state.tagStatistics,
    getApplicationsList: state => state.applicationsList,
    getIsLoading: state => state.isLoading,
  },
  mutations: {
    SET_APPLICATION_ID: (state, val) => {
      state.applicationId += val
    },
    INPUT_APPLICATION_ID: (state, val) => {
      state.applicationId = val
    },
    RESET_APPLICATION_ID: state => {
      state.applicationId = ''
    },
    SET_ACTION_TYPE: (state, val) => {
      state.actionType = val
    },
    SET_SELECTED_TAGS: (state, val) => {
      state.selectedTags = val
    },
    SET_SELECTED_TAG: (state, val) => {
      state.selectedTag = val
    },
    SET_TAG_SET_OPTIONS_LIST: (state, val) => {
      state.tagSetOptionsList = val
    },
    SET_TAG_STATISTICS: (state, val) => {
      state.tagStatistics = val
    },
    ADD_APPLICATION_TO_LIST: (state, val) => {
      state.applicationsList.push(val)
    },
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    RESET_STATE: state => {
      state.applicationId = ''
      state.actionType = 'add'
      state.selectedTags = []
      state.selectedTag = null
      state.tagSetOptionsList = []
      state.tagStatistics = []
      state.applicationsList = []
      state.isLoading = false
    },
  },
  actions: {
    async prepareTagSetsListOptions({ rootGetters, commit }) {
      try {
        const response = await fetchTagSetsOptionsList({
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        })

        commit('SET_TAG_SET_OPTIONS_LIST', response)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchTagStatistics({ commit }, { queryParams }) {
      try {
        const response = await axios.post('/auth/tags-statistics', queryParams)

        commit('SET_TAG_STATISTICS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async updateApplicationTags({ state, commit }) {
      try {
        const queryParams = {
          action: state.actionType,
          tag_ids: state.selectedTags,
        }
        commit('SET_IS_LOADING', true)

        const response = await axios.post(`/auth/applications/${state.applicationId}/tags`, queryParams)
        commit('ADD_APPLICATION_TO_LIST', response.data.data)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating Application Tags',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        commit('RESET_APPLICATION_ID')
        commit('SET_IS_LOADING', false)
      }
    },
    async updateApplicationTag({ state, commit }) {
      try {
        const queryParams = {
          tag_ids: [state.selectedTag.id],
        }
        // check tag type
        queryParams.action = state.selectedTag.selectType !== 'Radio' ? state.actionType : 'update'

        commit('SET_IS_LOADING', true)

        const response = await axios.post(`/auth/applications/${state.applicationId}/tags`, queryParams)
        commit('ADD_APPLICATION_TO_LIST', response.data.data)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating Application Tags',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        commit('RESET_APPLICATION_ID')
        commit('SET_IS_LOADING', false)
      }
    },
  },
}
